<template>
  <student-layout>
    <main class="px-4 py-8 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-7xl">
        <div class="">
          <div class="overflow-hidden rounded-xl bg-white shadow-lg">
            <div class="p-6">
              <h1 class="mb-8 text-center text-3xl font-bold text-purple-900 ">
                ¡MUY BIEN!
              </h1>
              <div class="grid gap-6 md:grid-cols-3">
                <div class="rounded-lg border-4 border-blue-800 bg-blue-100 p-4 text-center">
                  <h3 class="font-semibold text-blue-800">
                    Aciertos de Otros
                  </h3>
                  <p class="text-3xl font-bold text-blue-800">
                    {{ courseAveragePerformance }}%
                  </p>
                </div>

                <div class="rounded-lg border-4 border-green-800 bg-lime-100 p-4 text-center">
                  <h3 class="font-semibold text-green-800">
                    Porcentaje de acierto
                  </h3>
                  <p class="text-3xl font-bold text-green-800">
                    {{ personalPerformance }}%
                  </p>
                </div>

                <div class="rounded-lg border-4 border-amber-700 bg-yellow-100 p-4 text-center">
                  <h3 class="font-semibold text-amber-700">
                    Puntos Ganados
                  </h3>
                  <p class="text-3xl font-bold text-amber-700">
                    + {{ points }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 overflow-hidden rounded-xl bg-white shadow-lg">
          <div class="p-6">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Resumen de Preguntas
            </h3>
            <div class="space-y-4">
              <div
                v-for="(question, index) in questionStats"
                :key="index"
                class="grid cursor-pointer grid-cols-3 rounded-lg border-2 border-purple-100 bg-purple-50 p-4 hover:scale-[1.01]"
                @click="showQuestion(index)"
              >
                <div class="col-span-2 flex items-center">
                  <Icon
                    v-if="question.show"
                    icon="mingcute:down-line"
                    class="mr-3 text-xl text-purple-500"
                  />
                  <Icon
                    v-else
                    icon="mingcute:right-line"
                    class="mr-3 text-xl text-purple-500"
                  />
                  <Icon
                    v-if="question.correct"
                    icon="teenyicons:tick-circle-solid"
                    class="mr-3 text-xl text-green-500"
                  />
                  <Icon
                    v-if="question.answer === null"
                    icon="fa6-solid:circle-dot"
                    class="mr-3 text-xl text-yellow-500"
                  />
                  <Icon
                    v-if="question.correct==false"
                    icon="teenyicons:x-circle-solid"
                    class="mr-3 text-xl text-red-500"
                  />

                  <span class="font-medium text-gray-700">
                    Pregunta {{ parseInt(index)+1 }}:
                  </span>
                  <span
                    v-if="!question.show"
                    :id="'question_preview_'+index"
                    class=" ml-2 truncate text-gray-500 max-md:hidden md:max-w-sm"
                  >
                    {{ question.question }}
                  </span>
                </div>
                <span class="max-w-sm justify-self-end text-sm text-gray-600">
                  {{ question.correctPercentage }}% Respondió correctamente
                </span>
                <div
                  v-if="question.show"
                  class="col-span-3 mx-20 my-3 grid space-y-3"
                >
                  <span class="mb-2">
                    {{ question.question }}
                  </span>
                  <!-- Respuesta incorrecta -->
                  <div
                    v-if="question.answer != null && !question.correct"
                    class="w-full rounded-lg border-2 border-red-300 bg-purple-50 p-3 text-left text-gray-800 shadow-md ease-in-out"
                    :class="{'max-w-48 justify-self-center text-center': (question.type == 'true_false')}"
                  >
                    {{ question.type == "multiple_choice" ? question.multipleChoice.alternatives[question.answer] :
                      question.type == "true_false" ? (question.answer == true ? "Verdadero" : "Falso") :
                      question.type == "open" ? question.answer :
                      ""
                    }}
                  </div>
                  <!-- Respuesta Correcta   -->
                  <div
                    class="w-full rounded-lg border-2 border-green-300 bg-purple-50 p-3 text-left text-gray-800 shadow-md ease-in-out"
                    :class="{'max-w-48 justify-self-center text-center ': (question.type == 'true_false')}"
                  >
                    {{ question.type == "multiple_choice" ? question.multipleChoice.alternatives[question.multipleChoice.correctAlternative] :
                      question.type == "true_false" ? (question.trueOrFalse.correctAlternative == true ? "Verdadero" : "Falso") :
                      question.type == "open" ? (question.correct ? question.answer : question.open.correctResponse) :
                      ""
                    }}
                  </div>
                  <!-- Pregunta Omitida -->
                  <div
                    v-if="question.answer === null"
                    class="w-full rounded-lg border-2 border-yellow-300 bg-purple-50 p-3 text-left text-gray-800 shadow-md ease-in-out"
                  >
                    Pregunta omitida
                  </div>
                  <div class="justify-self-end">
                    <Button
                      @click="showQuestion(index); giveFeedback(index)"
                    >
                      Pedir Feedback
                    </Button>
                  </div>
                  <div
                    v-if="question.feedback"
                  >
                    <div class="font-semibold">
                      Feedback:
                    </div>
                    {{ question.feedback }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Falta arreglar este boton o pensarlo mejor, cuando la pantalla se acorta, el boton se esconde :7 -->
        <div class="fixed right-40 p-4">
          <Button
            @click="BackToIndex()"
          >
            Finalizar
          </Button>
        </div>
      </div>
    </main>
  </student-layout>
</template>


<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import { marked } from 'marked';
import questionsApi from '@/api/questions';
// import questionnariesApi from '@/api/questionnaires';
import {Icon} from '@iconify/vue';

import Button from '@/components/button.vue';
import questions from '@/api/questions';

export default {
  components: {
    Button,
    Icon,
  },
  data() {
    return {
      course: {},
      units: [],
      expandedUnit: null,
      selectedUnit: null,
      selectedTopic: null,
      selectedStudent: 11,
      currentQuestion: {},
      currentAnswer: '',
      finalAnswer: null,
      output: null,
      topicContent: '',
      currentQuestionContent: '',
      timings: {
        startReadQuestion: null,
        startSendResponse: null,
      },
      courseId: this.$route.params.courseId,

      personalPerformance: 75,
      courseAveragePerformance: 70,
      points: 300,

      questionStats: {
        0: {
          question: "En esta pregunta se hacen muchas preguntas preguntosas, como por ejemplo cuando uno habla de matematicas y siempre menciona a ciertos creadores de teoremas, como pitagoras o a veces euclides, no como en filosofia que estan los filosofos como Platon o Aristoteles, que pueden filosofar pero no calcular como los otros, entonces la Alternativa B es la correcta",
          correct: true,
          answer: "0",
          correctPercentage: 89.5,
          show: false,
          'multipleChoice': {
            'alternatives': ['function', 'func', '-definir'],
            'correctAlternative': '0',
            'questionId': '420',
          },
          'type': 'multiple_choice',
        },
        1: {
          question: "3+5?????",
          correct: false,
          answer: "2",
          correctPercentage: 50.7,
          show: false,
          'multipleChoice': {
            'alternatives': ['7', '4', '3', '9'],
            'correctAlternative': '3',
            'questionId': '69',
          },
          'type': 'multiple_choice',
        },
        2: {
          question: "Cuál de los siguientes se ocupa dentro del algoritmo de Shor: ",
          correct: null,
          answer: null,
          correctPercentage: 0.01,
          show: false,
          'multipleChoice': {
            'alternatives': ['Gauss', 'Fourier', 'Euler', 'Einstein'],
            'correctAlternative': '2',
            'questionId': '80085',
          },
          'type': 'multiple_choice',
        },
        3: {
          question: 'Cuando la temperatura de un cuerpo negro aumenta, la longitud de onda de su radiación aumenta. ',
          correct: false,
          answer: true, // esto podria estar de mas, considerando el 'correct'
          correctPercentage: 0.02,
          show: false,
          'trueOrFalse': {
            'correctAlternative': false,
            'questionId': '1234',
          },
          'type': 'true_false',
        },
        4: {
          question: 'Le pagan su sueldo del mes en su empresa, pero se encuentra con un gran dilema. La compañia ha estado usando modelos de IA entrenados con datos extraidos sin permiso de los autores. Usted debe decidir si gastar su dinero en skins de LoL o comprando cartas Yu-Gi-Oh. Justifique su respuesta: ',
          correct: false,
          answer: 'La respuesta es comprar skins de LoL, ya que pueden venderse.',
          correctPercentage: 60.4,
          'open': {
            'correctResponse': 'La respuesta es un dilema, ya que gastar en skins de lol es caro y no sirve, mientras que gastar en yugioh es caro, pero puede ser valioso si se encuentra en este mundo. La respuesta correcta debería ser yugioh',
            'questionId': '4321',
          },
          'type': 'open',
        },
      },
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
      this.getQuestionnaire();
    },
  },
  async mounted() {
    this.getCourse();
    this.getQuestionnaire();
  },
  methods: {
    async getQuestionnaire(){},
    async getCourse() {
      const response = await coursesApi.getById(this.courseId, {
        withUnitsTopics: true,
      });
      this.course = response.data.data;
      this.units = this.course.units;

      document.title = 'Curso ' + this.course.name;
    },
    showQuestion(index){
      let i;
      for (i in this.questionStats) {
        if (i == index) continue;
        this.questionStats[i].show = false;
      }
      this.questionStats[index].show = !this.questionStats[index].show;
      console.log(this.questionStats[index]);
    },

    giveFeedback(index){
      this.questionStats[index].feedback = 'Este es el feedback de la pregunta ' + (parseInt(index)+1);
    },

    BackToIndex() {
      this.$router.push({ name: 'student.exercise.index' });
    },
  },
};
</script>
