import { defineStore } from 'pinia';

export const useCourseStore = defineStore('course', {
  state: () => ({
    course: {},
    courses: [],
  }),
  actions: {
    setCourse(id) {
      this.course = id;
    },
    clearCourse() {
      this.course = null;
    },
    setCourses(courses) {
      this.courses = courses;
    },
    clearCourses() {
      this.courses;
    },
  },
  persist: true,
});
