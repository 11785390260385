<template>
  <div class="rounded-lg bg-white p-6 shadow-md">
    <h2 class="mb-4 text-2xl font-bold text-gray-800">
      Unidades del curso {{ courseName }}
    </h2>
    <ul class="space-y-2">
      <li
        v-for="unit in units"
        :key="unit.id"
        class="border-b border-gray-200 last:border-b-0"
      >
        <div
          v-if="unit.isVisible"
          class="flex cursor-pointer items-center justify-between py-2 text-gray-700 hover:text-gray-900"
          @click="toggleUnit(unit.id)"
        >
          <span class="font-medium">{{ unit.name }}</span>
          <span class="text-gray-500">
            <Icon
              v-if="expandedUnit === unit.id"
              icon="mdi:chevron-up"
              class="size-5"
            />
            <Icon
              v-else
              icon="mdi:chevron-down"
              class="size-5"
            />
          </span>
        </div>
        <ul
          v-if="expandedUnit === unit.id"
          class="mb-3 ml-4 mt-2 space-y-1"
        >
          <li
            v-for="topic in unit.topics"
            :key="topic.id"
          >
            <a
              v-if="topic.isVisible"
              class="block cursor-pointer rounded-md px-2 py-1 text-gray-600 hover:bg-purple-50 hover:text-gray-900"
              @click="$emit('show-topic', unit, topic)"
            >
              {{ topic.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

export default {
  components: {
    Icon,
  },
  props: {
    units: {
      type: Array,
      required: true,
    },
    courseName: {
      type: String,
      required: true,
    },
  },
  //Para manejar que se cierre un dropdown cuando otro se clickee
  setup(props, { emit }) {
    const expandedUnit = ref(null);

    const toggleUnit = (unitId) => {
      if (expandedUnit.value === unitId) {
        expandedUnit.value = null;
      } else {
        expandedUnit.value = unitId;
      }
      emit('toggle-unit', unitId);
    };

    return {
      expandedUnit,
      toggleUnit,
    };
  },
};
</script>
