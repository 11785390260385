import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
  }),
  actions: {
    setUser(id, name) {
      this.user = {
        id,
        name,
      };
    },
    clearUser() {
      this.user = null;
    },
  },
  persist: true,
});
