<template>
  <div class="flex min-h-screen items-center justify-center bg-purple-50">
    <div class="w-full max-w-md">
      <transition
        appear
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div class="rounded-lg bg-white p-8 shadow-2xl">
          <h2 class="mb-6 text-center text-3xl font-bold text-purple-700">
            Inicio sesión
          </h2>
          <form
            class="space-y-6"
            @submit.prevent="handleLogin"
          >
            <transition-group
              enter-active-class="transition duration-300 ease-out"
              enter-from-class="transform -translate-x-4 opacity-0"
              enter-to-class="transform translate-x-0 opacity-100"
              leave-active-class="transition duration-200 ease-in"
              leave-from-class="transform translate-x-0 opacity-100"
              leave-to-class="transform -translate-x-4 opacity-0"
            >
              <div
                key="email"
                class="overflow-hidden"
              >
                <label
                  for="email"
                  class="block text-sm font-medium text-purple-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
              <div
                key="password"
                class="overflow-hidden"
              >
                <label
                  for="password"
                  class="block text-sm font-medium text-purple-700"
                >
                  Contraseña
                </label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
            </transition-group>
            <div>
              <button
                type="submit"
                :disabled="isLoading"
                class="w-full rounded-md bg-gradient-to-r from-purple-500 to-purple-700 px-4 py-2 text-white transition duration-300 ease-in-out hover:scale-105 hover:from-purple-600 hover:to-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 active:scale-95 disabled:opacity-50"
              >
                {{ isLoading ? 'Iniciando sesión...' : 'Acceder' }}
              </button>
            </div>
          </form>
          <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform -translate-y-2 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-2 opacity-0"
          >
            <div
              v-if="loginResponse && loginResponse.data"
              class="mt-4 text-center text-red-600"
            >
              <p v-if="loginResponse.data.email">
                {{ translateError(loginResponse.data.email[0]) }}
              </p>
              <p v-if="loginResponse.data.password">
                {{ translateError(loginResponse.data.password[0]) }}
              </p>
              <p v-if="loginResponse.data.message">
                {{ translateError(loginResponse.data.message) }}
              </p>
            </div>
          </transition>
        </div>
      </transition>
      <transition
        appear
        enter-active-class="transition duration-300 delay-300 ease-out"
        enter-from-class="transform -translate-y-2 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-2 opacity-0"
      >
        <p class="mt-4 text-center text-sm text-purple-600">
          ¿No tienes una cuenta?
          <router-link
            to="/register"
            class="font-medium text-purple-700 transition duration-300 ease-in-out hover:text-purple-800"
          >
            Regístrate
          </router-link>
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import usersApi from '@/api/users';
import { useUserStore } from '@/stores/userStore';

export default {
  setup(){
    const userStore = useUserStore();
    return{
      userStore,
    };
  },
  data() {
    return {
      email: '',
      password: '',
      loginResponse: '',
      errorTranslations: {
        'The email has already been taken.': 'Este correo electrónico ya está en uso.',
        'The password field must be at least 8 characters.': 'La contraseña debe tener al menos 8 caracteres.',
        'The email field is required.': 'El campo de correo electrónico es obligatorio.',
        'The password field is required.': 'El campo de contraseña es obligatorio.',
        'These credentials do not match our records.': 'Estas credenciales no coinciden con nuestros registros.',
      },
    };
  },
  async mounted(){
    usersApi.csrfCookie();
    this.user = await usersApi.getSession();
    if (this.user) {
      this.$router.push('/');
    }else{
      this.userStore.clearUser();
    }
  },
  methods: {
    async handleLogin() {
      try{
        this.loginResponse = await usersApi.login({
          email: this.email,
          password: this.password,
        });

        this.userStore.setUser();
        this.user = (await usersApi.getSession()).data;
        this.userStore.setUser(this.user.id, this.user.name);
        this.$router.push('/');
      }
      catch(error){
        this.loginResponse = error.response;
      }
    },
    translateError(error) {
      return this.errorTranslations[error] || error;
    },
  },
};
</script>
