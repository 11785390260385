<template>
  <student-layout>
    <main class="p-8">
      <div class="mx-auto max-w-6xl">
        <h1 class="mb-8 text-5xl font-bold text-black">
          Bienvenido a tus cursos :D
        </h1>
        <!--
        <div class="mb-8 flex space-x-4 overflow-x-auto">
          <button class="whitespace-nowrap rounded-full bg-white px-4 py-2 font-semibold text-indigo-600 transition duration-300 ease-in-out hover:bg-indigo-100">
            All
          </button>
          <button
            v-for="category in categories"
            :key="category"
            class="flex items-center whitespace-nowrap rounded-full bg-indigo-300 px-4 py-2 font-semibold text-white transition duration-300 ease-in-out hover:bg-indigo-400"
          >
            {{ category }}
            <Icon
              icon="mdi:chevron-down"
              class="ml-1 size-5"
            />
          </button>
        </div>
-->
        <h2 class="mb-4 text-2xl font-semibold text-black">
          Cursos:
        </h2>

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div
            v-for="(course, index) in sortedCourses"
            :key="course.id"
            class="opacity-0 transition-all duration-500 ease-out"
            :class="{
              'translate-y-0 opacity-100': true,
              'delay-100': index === 1,
              'delay-200': index === 2,
              'delay-300': index === 3
            }"
          >
            <div
              class="flex overflow-hidden rounded-lg bg-white shadow-lg transition duration-300 ease-in-out hover:-translate-y-1 hover:shadow-xl"
            >
              <!-- ZONA MORADA CLICKEABLE -->
              <a
                href="#"
                class="flex w-2/5 flex-col justify-between bg-indigo-900 p-6"
                @click.prevent="goToCourse(course)"
              >
                <div>
                  <h3 class="mb-2 text-xl font-bold text-white">
                    {{ course.name }}
                  </h3>
                  <p class="text-sm text-indigo-300">
                    {{ course.category }}
                  </p>
                </div>
              </a>

              <!-- ZONA BLANCA NO CLICKEABLE -->
              <div class="flex w-3/5 flex-col justify-between p-6">
                <div>
                  <h4 class="mb-2 text-xl font-semibold">
                    {{ course.name }}
                  </h4>
                  <p class="mb-2 text-sm text-gray-600">
                    <strong>Profesor:</strong> {{ course.professor }}
                  </p>
                  <p class="mb-2 text-sm text-gray-600">
                    <strong>Horario:</strong> {{ course.schedule }}
                  </p>
                  <p class="mb-4 text-sm text-gray-600">
                    {{ course.description }}
                  </p>
                </div>
                <Button
                  class="bg-purple w-full rounded-lg px-4 py-2 font-semibold text-white transition duration-300 ease-in-out hover:bg-purple-700"
                  @click="goToCourse(course)"
                >
                  Acceder al curso
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </student-layout>
</template>

<script>
import { Icon } from '@iconify/vue';
import coursesApi from '@/api/courses';
import Button from '@/components/button.vue';
import { useCourseStore } from '@/stores/courseStore';

export default {
  components: {
    Button,
    Icon,
  },
  data() {
    return {
      courses: [],
      categories: ['IT & Software', 'Business', 'Media Training', 'Interior'],
      Professors: [
        'Dr. Ana García',
        'Prof. Carlos Rodríguez',
        'Ing. María López',
        'Lic. Juan Martínez',
        'Dra. Laura Sánchez',
      ],
      Schedules: [
        'Lunes y Miércoles, 10:00 - 12:00',
        'Martes y Jueves, 15:00 - 17:00',
        'Viernes, 09:00 - 13:00',
        'Sábados, 08:00 - 12:00',
        'Lunes a Viernes, 18:00 - 20:00',
      ],
      Descriptions: [
        'Aprende los fundamentos y técnicas avanzadas en este curso intensivo.',
        'Desarrolla habilidades prácticas para destacar en el mundo profesional.',
        'Explora las últimas tendencias y herramientas del sector.',
        'Un curso diseñado para impulsar tu carrera y ampliar tus conocimientos.',
        'Domina las mejores prácticas y metodologías de la industria.',
      ],
    };
  },
  computed: {
    sortedCourses() {
      return [...this.courses]
        .sort((a, b) => (b.students || 0) - (a.students || 0))
        .slice(0, 4)
        .map(course => ({
          ...course,
          professor: this.getRandomItem(this.Professors),
          schedule: this.getRandomItem(this.Schedules),
          description: this.getRandomItem(this.Descriptions),
        }));
    },
  },
  async mounted() {
    const response = await coursesApi.getAll();
    this.courses = response.data.data;
  },
  methods: {
    goToCourse(course) {
      const courseStore = useCourseStore();
      courseStore.setCourse(course);
      this.$router.push({ name: 'student.course.welcome', params: { courseId: course.id } });
    },
    getRandomItem(array) {
      return array[Math.floor(Math.random() * array.length)];
    },
  },
};
</script>

