<template>
  <student-layout>
    <main class="py-12">
      <div class="container mx-auto px-4">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
        <div class="flex flex-col gap-8 lg:flex-row">
          <!-- Sidebar -->
          <div class="w-full shrink-0 lg:w-1/4 xl:w-1/5">
            <SidebarExercise
              :units="units"
              :course-name="course.name"
              :expanded-unit="expandedUnit"
              class="sticky top-8"
              @toggle-unit="toggleUnit"
              @show="showTopicQuestionnaires"
            />
          </div>

          <!-- Main Content -->
          <div class="w-full lg:w-2/4 xl:w-3/5">
            <div class="rounded-lg bg-white shadow-lg">
              <div class="mb-8 flex flex-col space-y-6 p-8">
                <Title
                  text="Comenzar un nuevo cuestionario"
                  class="mb-2 text-3xl font-bold text-gray-800"
                />
                <div class="text-lg font-medium text-gray-600">
                  Contenido del cuestionario: <span class="text-purple-600">{{ selectedName }}</span>
                </div>
                <div class="space-y-2">
                  <LabelComponent
                    for=""
                    text="Tipo de pregunta:"
                    class="text-lg font-semibold text-gray-700"
                  />
                  <ul class="space-y-2">
                    <li
                      v-for="(type, index) in questionTypes"
                      :key="index"
                      class="flex items-center"
                    >
                      <input
                        :id="type.value"
                        v-model="creatingQuestionnaire.questionTypes"
                        :value="type.value"
                        type="checkbox"
                        class="size-5 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                      >
                      <label
                        :for="type.value"
                        class="ml-2 text-gray-700"
                      >{{ type.label }}</label>
                    </li>
                  </ul>
                </div>
                <div>
                  <LabelComponent
                    for="quantity"
                    text="Cantidad de preguntas"
                    class="mb-2 block text-lg font-semibold text-gray-700"
                  />
                  <div class="mb-2 flex items-center">
                    <input
                      id="quantity"
                      v-model="creatingQuestionnaire.quantity"
                      type="number"
                      class="w-24 rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                    >
                    <span class="ml-2 text-sm text-gray-500">preguntas</span>
                  </div>
                  <LabelComponent
                    for="difficulty"
                    text="Dificultad"
                    class="mb-2 block text-lg font-semibold text-gray-700"
                  />
                  <div class="">
                    <select
                      id="difficulty"
                      v-model="creatingQuestionnaire.difficulty"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                      focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option
                        v-for="(type, index) in difficultyTypes"
                        :key="index"
                        :value="type.value"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <Button
                    class="w-full bg-purple-600 hover:bg-purple-700 focus:ring-purple-500"
                    @click="goToQuestionnaire(creatingQuestionnaire)"
                  >
                    Comenzar Cuestionario
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <!-- Questionnaire History -->
          <div class="w-full lg:w-2/4 xl:w-1/5">
            <div class="rounded-lg bg-white shadow-lg">
              <div class="mb-8 flex flex-col space-y-4 p-6">
                <Title
                  text="Historial"
                  class="mb-4 text-2xl font-bold text-gray-800"
                />
                <div
                  v-if="questionnaireHistory"
                  class="overflow-x-auto"
                >
                  <table class="w-full">
                    <thead>
                      <tr class="bg-purple-100">
                        <th class="p-3 text-left text-sm font-semibold text-purple-800">
                          Correctas
                        </th>
                        <th class="p-3 text-left text-sm font-semibold text-purple-800">
                          Nota
                        </th>
                        <th class="p-3 text-left text-sm font-semibold text-purple-800">
                          Fecha
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="questionnaire in questionnaireHistory"
                        :key="questionnaire.id"
                        class="border-b border-gray-200 hover:bg-gray-50"
                      >
                        <td class="p-3 text-sm text-gray-700">
                          {{ questionnaire.corrects }}/{{ questionnaire.totalQuestions }}
                        </td>
                        <td class="p-3 text-sm text-gray-700">
                          {{ questionnaire.percentage }}%
                        </td>
                        <td class="p-3 text-sm text-gray-700">
                          {{ questionnaire.date }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p
                  v-else
                  class="text-center text-gray-600"
                >
                  Aún no has realizado ningún cuestionario en este tópico
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </student-layout>
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import { marked } from 'marked';
import questionsApi from '@/api/questions';
// import questionnariesApi from '@/api/questionnaires';

import Sidebar from '@/components/sidebar.vue';
import Title from '@/components/title.vue';
import Button from '@/components/button.vue';
import SidebarExercise from '@/components/sidebarExercise.vue';
import LabelComponent from '@/components/label.vue';

export default {
  components: {
    Sidebar,
    Title,
    Button,
    SidebarExercise,
    LabelComponent,
  },
  data() {
    return {
      course: {},
      units: [],
      expandedUnit: null,
      selectedUnit: null,
      selectedTopic: null,
      selectedStudent: 11,
      currentQuestion: {},
      currentAnswer: '',
      finalAnswer: null,
      localFeedback: null,
      generalFeedback: null,
      output: null,
      topicContent: '',
      currentQuestionContent: '',
      timings: {
        startReadQuestion: null,
        startSendResponse: null,
      },
      courseId: this.$route.params.courseId,
      questionnaireHistory: null,
      selectedName: null,
      creatingQuestionnaire: {
        'creatingFrom': 'questionnaire',
        'selectedType': 'course',
        'selectedId': this.courseId,
        'questionTypes': ['multiple_choice'],
        'quantity': 10,
        'difficulty': 'recommended',
      },
      questionTypes: [
        { value: 'multiple_choice', label: 'Alternativas' },
        { value: 'true_false', label: 'Verdadero o Falso' },
        { value: 'open', label: 'Respuesta Abierta (Desarrollo)' },
      ],
      difficultyTypes: [
        { value: 'recommended', label: 'Recomendada' },
        { value: 'easy', label: 'Fácil' },
        { value: 'medium', label: 'Media' },
        { value: 'hard', label: 'Difícil' },
      ],
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
      this.getQuestionnaire();
    },
  },
  async mounted() {
    this.getCourse();
    this.getQuestionnaire();
  },
  methods: {
    goBack() {
      const courseId = this.$route.params.courseId; // Obtiene el ID del curso desde la ruta actual
      this.$router.push(`/student/course/${courseId}/welcome`); // Navega a la página de bienvenida
    },
    async getCourse() {
      const response = await coursesApi.getById(this.courseId, {
        withUnitsTopics: true,
      });
      this.course = response.data.data;
      this.units = this.course.units;
      this.selectedName = this.course.name;

      document.title = 'Curso ' + this.course.name;
    },
    async getQuestionnaire(){},
    async showTopicQuestionnaires(unit, topic, type) {
      this.currentQuestion = {};
      this.currentAnswer = '';
      this.output = null;
      this.localFeedback = null;
      this.generalFeedback = null;
      this.currentQuestionContent = '';
      this.selectedUnit = unit;
      this.selectedTopic = topic;
      this.topicContent = '';

      var selectedId = null;
      switch(type){
      case 'course':
        selectedId = this.courseId;
        this.selectedName = this.course.name;
        break;
      case 'unit':
        selectedId = this.selectedUnit.id;
        this.selectedName = this.selectedUnit.name;
        break;
      case 'topic':
        selectedId = this.selectedTopic.id;
        this.selectedName = this.selectedTopic.name;
        break;
      default:
        console.log("Error: algo salió mal al seleccionar ¯\_(ツ)_/¯");
      }

      // TODO Implementar API
      //this.questionnaireHistory = (await questionnairesApi.getById(this.selectedId, {selectBy: type})).data.data;

      // A borrar - Datos estaticos a probar //////////////////////////////////////////////////////////////////
      if (topic.name == 'Tipos de funciones en JavaScript.') {
        this.questionnaireHistory = null;
      } else {
        this.questionnaireHistory = {
          0: {'id': 1, 'corrects': 2, 'totalQuestions': 3, 'percentage': 66.7, 'date': '22/10/26'},
          1: {'id': 3, 'corrects': 3, 'totalQuestions': 3, 'percentage': 100.0, 'date': '18/09/26'},
        };
      }
      /////////////////////////////////////////////////////////////////////////////
    },

    goToQuestionnaire(questionnaire) {
      // TODO Implementar API
      // const courseStore = useCourseStore();
      // courseStore.setCourse(course);
      this.$router.push({ name: 'student.exercise.show', params: { questionnaireId: 0 } });
    },
  },
};
</script>
