import api from './index';

export default {
  getAll(params) {
    return api.get('/api/courses', { params });
  },
  getById(id, params) {
    return api.get(`/api/courses/${id}`, { params });
  },
  update(id, data) {
    return api.patch(`/api/courses/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/courses/${id}`);
  },
  create(data) {
    return api.post('/api/courses', data);
  },
  downloadGuide(courseId) {
    return api.get(`/api/courses/${courseId}/download-guide`, { responseType: 'blob', timeout: 600000 });
  },
  units: {
    getAll(courseId){
      return api.get(`/api/courses/${courseId}/units`);
    },
    create(courseId, data) {
      return api.post(`/api/courses/${courseId}/units`, data);
    },
  },
  topics: {
    getAll(courseId) {
      return api.get(`/api/courses/${courseId}/topics`);
    },
    create() {
      throw new Error('Not implemented');
    },
  },
  questions: {
    getAll(courseId, params) {
      return api.get(`/api/courses/${courseId}/questions`, { params });
    },
  },
  chats: {
    create(courseId, data ) {
      return api.post(`/api/courses/${courseId}/chats`, data);
    },
    getAll(courseId, params) {
      return api.get(`/api/courses/${courseId}/chats`, { params });
    },
  },
  files: {
    getAll(courseId, params) {
      return api.get(`/api/courses/${courseId}/files`, { params });
    },
    create(courseId, data) {
      return api.post(`/api/courses/${courseId}/files`, data);
    },
    analyzeTopics(courseId, data) {
      return api.post(`/api/courses/${courseId}/get-topics-from-file`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    generateHierarchy(courseId, data) {
      return api.post(`/api/courses/${courseId}/awsfiles/get-hierarchy`, data);
    },
    sendHierarchy(courseId, data) {
      return api.post(`/api/courses/${courseId}/awsfiles/send-hierarchy`, data);
    },
  },
};
