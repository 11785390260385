<template>
  <student-layout>
    <div class="pt-20">
      <Title :text="`Archivos del tema: ${topic.name}`" />
      <div>
        <ul class="mb-2 list-disc rounded p-4 pl-5">
          <li
            v-for="file in files"
            :key="file.id"
            class="mb-1 ml-4"
          >
            <a
              :href="file.url"
              target="_blank"
              class="text-blue-500 underline hover:text-blue-700"
            >
              {{ file.path }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </student-layout>
</template>
<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import Sidebar from '@/components/sidebar.vue';
import Title from '@/components/title.vue';
import Button from '@/components/button.vue';

export default {
  components: {
    Sidebar,
    Title,
    Button,
  },
  props: {
    selectedTopic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      topic: {},
      files: [],
    };
  },
  async mounted() {
    this.topic = (await topicsApi.getById(this.$route.params.topicId)).data.data;
    this.files = (await topicsApi.awsfiles.getAll(this.$route.params.courseId, this.$route.params.topicId)).data.data;
  },
  methods: {
    async getFilesForTopic(topicId) {
      const files = (await topicsApi.awsfiles.getAll(topicId)).data.data;

      this.topics = this.topics.map((topic) => {
        if (topic.id === topicId) {
          topic.files = files;
        }
        return topic;
      });
    },
  },
};
</script>
