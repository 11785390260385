<template>
  <div class="">
    <header class="bg-purple-700 p-6 text-white shadow-lg">
      <div class="container mx-auto flex items-center justify-between">
        <!-- Logo y texto alineados -->
        <div class="flex items-center">
          <!-- Logo -->
          <img
            :src="logo"
            alt="Ranner Logo"
            class="mr-8 h-20 w-auto"
          >
          <div>
            <h1 class="text-4xl font-bold">
              RANNER
            </h1>
            <p class="mt-2 text-xl">
              Tu espacio de aprendizaje interactivo
            </p>
          </div>
        </div>
      </div>
    </header>

    <main class="container mx-auto px-4 py-8">
      <section class="mb-12 text-center">
        <h2 class="mb-4 text-3xl font-bold">
          Bienvenido a RANNER
        </h2>
        <p class="mx-auto max-w-2xl text-lg text-gray-600">
          Nuestra plataforma de vanguardia ofrece herramientas avanzadas para profesores y estudiantes,
          facilitando la creación y el acceso a materiales de estudio enriquecidos con IA.
        </p>
      </section>

      <div class="mb-12 grid gap-8 md:grid-cols-2">
        <section class="rounded-lg bg-white p-6 shadow-md transition duration-300 hover:shadow-lg">
          <h3 class="mb-4 flex items-center text-2xl font-semibold">
            <Icon
              icon="mdi:account"
              class="mr-2 text-purple-700"
            /> Para Profesores
          </h3>
          <ul class="mb-6 space-y-3">
            <li class="flex items-center">
              <Icon
                icon="mdi:check-circle"
                class="mr-2 text-purple-700"
              />
              Sube archivos de estudio existentes
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:sparkles"
                class="mr-2 text-purple-700"
              />
              Genera nuevos materiales con IA
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:folder"
                class="mr-2 text-purple-700"
              />
              Organiza y gestiona contenidos fácilmente
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:chart-line"
                class="mr-2 text-purple-700"
              />
              Mejora el rendimiento de tus estudiantes
            </li>
          </ul>
          <Button
            @click="goToTeacherCourses"
          >
            Acceder como Profesor
          </Button>
        </section>

        <section class="rounded-lg bg-white p-6 shadow-md transition duration-300 hover:shadow-lg">
          <h3 class="mb-4 flex items-center text-2xl font-semibold">
            <Icon
              icon="mdi:school"
              class="mr-2 text-purple-700"
            /> Para Estudiantes
          </h3>
          <ul class="mb-6 space-y-3">
            <li class="flex items-center">
              <Icon
                icon="mdi:book-open-page-variant"
                class="mr-2 text-purple-700"
              />
              Accede a materiales de estudio interactivos
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:brain"
                class="mr-2 text-purple-700"
              />
              Genera preguntas de práctica con IA
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:trophy"
                class="mr-2 text-purple-700"
              />
              Prepárate para tus evaluaciones
            </li>
            <li class="flex items-center">
              <Icon
                icon="mdi:pencil"
                class="mr-2 text-purple-700"
              />
              Aprende con diferentes métodos.
            </li>
          </ul>
          <Button
            @click="goToStudentCourses"
          >
            Acceder como Estudiante
          </Button>
        </section>
      </div>

      <section class="mb-12">
        <h3 class="mb-6 text-center text-2xl font-bold">
          ¿Por qué elegir RANNER?
        </h3>
        <div class="grid gap-6 md:grid-cols-3">
          <div class="group relative rounded-lg bg-white p-4 text-center shadow transition-transform duration-300 hover:scale-105">
            <Icon
              icon="mdi:rocket"
              class="mx-auto mb-2 text-4xl text-purple-600"
            />
            <h4 class="mb-2 font-semibold">
              Aprendizaje Acelerado
            </h4>
            <p class="text-sm text-gray-600">
              Nuestros métodos basados en IA te ayudan a aprender más rápido y de manera más efectiva.
            </p>
            <span class="absolute bottom-0 left-0 h-1 w-full scale-x-0 bg-purple-700 transition-transform duration-300 group-hover:scale-x-100" />
          </div>

          <div class="group relative rounded-lg bg-white p-4 text-center shadow transition-transform duration-300 hover:scale-105">
            <Icon
              icon="mdi:certificate"
              class="mx-auto mb-2 text-4xl text-purple-600"
            />
            <h4 class="mb-2 font-semibold">
              Certificaciones Reconocidas
            </h4>
            <p class="text-sm text-gray-600">
              Obtén certificaciones valoradas por empresas líderes en la industria.
            </p>
            <span class="absolute bottom-0 left-0 h-1 w-full scale-x-0 bg-purple-700 transition-transform duration-300 group-hover:scale-x-100" />
          </div>

          <div class="group relative rounded-lg bg-white p-4 text-center shadow transition-transform duration-300 hover:scale-105">
            <Icon
              icon="mdi:account-multiple"
              class="mx-auto mb-2 text-4xl text-purple-600"
            />
            <h4 class="mb-2 font-semibold">
              Comunidad Activa
            </h4>
            <p class="text-sm text-gray-600">
              Únete a una comunidad global de estudiantes y profesores apasionados por el aprendizaje.
            </p>
            <span class="absolute bottom-0 left-0 h-1 w-full scale-x-0 bg-purple-700 transition-transform duration-300 group-hover:scale-x-100" />
          </div>
        </div>
      </section>


      <section class="mb-12 rounded-lg bg-purple-50 p-6">
        <h3 class="mb-4 text-center text-2xl font-bold">
          Estadísticas de RANNER
        </h3>
        <div class="grid gap-4 md:grid-cols-4">
          <div class="text-center">
            <p class="text-3xl font-bold text-purple-600">
              100,000+
            </p>
            <p class="text-sm text-purple-800">
              Estudiantes Activos
            </p>
          </div>
          <div class="text-center">
            <p class="text-3xl font-bold text-purple-600">
              5,000+
            </p>
            <p class="text-sm text-purple-800">
              Cursos Disponibles
            </p>
          </div>
          <div class="text-center">
            <p class="text-3xl font-bold text-purple-600">
              1,000+
            </p>
            <p class="text-sm text-purple-800">
              Instructores Expertos
            </p>
          </div>
          <div class="text-center">
            <p class="text-3xl font-bold text-purple-600">
              98%
            </p>
            <p class="text-sm text-purple-800">
              Tasa de Satisfacción
            </p>
          </div>
        </div>
      </section>
    </main>

    <footer class="mt-12 bg-purple-900 py-8 text-white">
      <div class="container mx-auto px-4">
        <div class="grid gap-8 md:grid-cols-4">
          <div>
            <h4 class="mb-4 text-lg font-semibold">
              RANNER
            </h4>
            <p class="text-sm">
              Transformando la educación con tecnología e innovación.
            </p>
          </div>
          <div>
            <h4 class="mb-4 text-lg font-semibold">
              Enlaces Rápidos
            </h4>
            <ul class="space-y-2 text-sm">
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Inicio</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Cursos</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Sobre Nosotros</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Contacto</a>
              </li>
            </ul>
          </div>
          <div>
            <h4 class="mb-4 text-lg font-semibold">
              Soporte
            </h4>
            <ul class="space-y-2 text-sm">
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >FAQ</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Ayuda</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Política de Privacidad</a>
              </li>
              <li>
                <a
                  href="#"
                  class="hover:underline"
                >Términos de Servicio</a>
              </li>
            </ul>
          </div>
          <div>
            <h4 class="mb-4 text-lg font-semibold">
              Síguenos
            </h4>
            <div class="flex space-x-4">
              <a
                href="#"
                class="text-2xl hover:text-gray-400"
              ><Icon icon="mdi:facebook" /></a>
              <a
                href="#"
                class="text-2xl hover:text-gray-400"
              ><Icon icon="mdi:twitter" /></a>
              <a
                href="#"
                class="text-2xl hover:text-pink-400"
              ><Icon icon="mdi:instagram" /></a>
              <a
                href="#"
                class="text-2xl hover:text-gray-400"
              ><Icon icon="mdi:linkedin" /></a>
            </div>
          </div>
        </div>
        <div class="mt-8 text-center text-sm">
          <p>&copy; 2024 RANNER. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Button from '@/components/button.vue';
import logo from '@/assets/ranner500.png';

export default {
  name: 'HomeView',
  components: {
    Button,
    Icon,
  },
  data(){
    return {
      logo,
    };
  },
  methods: {
    goToStudentCourses() {
      this.$router.push({ name: 'student.course.index' });
    },
    goToTeacherCourses() {
      this.$router.push({ name: 'teacher.course.index' });
    },
  },
};
</script>
