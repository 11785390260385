<template>
  <teacher-layout>
    <main class="">
      <div class="container p-4">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
      </div>
      <div :class="`flex ${isCreatingQuestionModalOpen ? 'overflow-hidden' : 'overflow-auto'} justify-between`">
        <div class="flex w-full flex-col space-y-2 p-6 md:w-3/4">
          <h1 class="text-4xl font-bold">
            Preguntas para el curso {{ courseStore?.course?.name }}:
          </h1>
          <div>
            Filtrar por :
          </div>
          <div class="flex flex-col gap-8 md:flex-row">
            <div class="w-full">
              <div class="relative flex w-full space-x-2">
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Units"
                    text="Unidades"
                  />
                  <select
                    id="Units"
                    v-model="filterQuestions.unitId"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option
                      v-for="unit in course.units"
                      :key="unit.id"
                      :value="unit.id"
                    >
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Topics"
                    text="Temas"
                  />
                  <select
                    id="Topics"
                    v-model="filterQuestions.topicId"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option
                      v-for="topic in topics"
                      :key="topic.id"
                      :value="topic.id"
                    >
                      {{ topic.name }}
                    </option>
                  </select>
                </div>
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Type"
                    text="Tipo"
                  />
                  <select
                    id="Type"
                    v-model="filterQuestions.type"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="multiple_choice">
                      Selección múltiple
                    </option>
                    <option value="true_false">
                      Desarrollo
                    </option>
                  </select>
                </div>
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Difficulty"
                    text="Dificultad"
                  />
                  <select
                    id="Type"
                    v-model="filterQuestions.difficulty"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="easy">
                      Fácil
                    </option>
                    <option value="medium">
                      Medio
                    </option>
                    <option value="hard">
                      Difícil
                    </option>
                  </select>
                </div>
                <div class="content-end">
                  <a
                    class="cursor-pointer font-bold text-purple-700"
                    @click="cleanFilteredQuestions()"
                  >
                    Limpiar filtros
                  </a>
                </div>
                <div class="content-end">
                  <button
                    @click="openModal(question)"
                  >
                    <div>
                      <Icon
                        icon="ri:ai-generate"
                        class="mb-3 text-3xl text-purple-700 hover:text-purple-500"
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 flex flex-col space-x-2 rounded-lg border-2 bg-white shadow">
            <div
              v-for="question in filteredQuestions"
              :key="question.id"
              class="flex flex-row items-center justify-between border-y border-gray-300 px-4 py-2  hover:bg-gray-50"
            >
              <button
                class="flex w-full flex-row justify-between space-x-4"
                @click="setEditingQuestion(question)"
              >
                <div class="flex items-center justify-between">
                  <div class=" text-start text-sm">
                    {{ question.question.length > 35 ? question.question.substring(0, 35) + '...' : question.question }}
                  </div>
                </div>
                <div class="flex items-center space-x-2 text-xs">
                  <div>
                    <div
                      :class="`${difficultyStyles(question.difficulty)} px-1 rounded`"
                    >
                      {{ translateDifficultyToText(question.difficulty) }}
                    </div>
                  </div>
                  <div
                    v-for="unit in course.units"
                    :key="unit.id"
                  >
                    <div
                      v-for="topic in unit.topics"
                      :key="topic.id"
                    >
                      <div
                        v-if="question.topicId == topic.id"
                        class="flex space-x-2"
                      >
                        <div class="rounded bg-gray-200 px-1">
                          {{ unit.name }}
                        </div>
                        <div class="rounded bg-gray-200 px-1">
                          {{ topic.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
              <button
                @click="openModal(question)"
              >
                <div>
                  <Icon
                    icon="ri:ai-generate"
                    class="text-xl text-gray-500"
                  />
                </div>
              </button>
            </div>
          </div>
          <div
            v-if="filterQuestions.generatedByAI"
            class="flex justify-end"
          >
            <Button
              @click="saveGeneratedQuestions()"
            >
              Aceptar preguntas generadas
            </Button>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <div class="m-4 flex flex-col space-y-2 rounded-lg bg-white p-6 shadow">
            <div class="text-xl font-bold">
              Pregunta seleccionada
            </div>
            <div class="flex flex-col space-y-2">
              <LabelComponent
                for="question"
                text="Enunciado"
              />
              <textarea
                v-model="editingQuestion.question"
                type="text"
                class="min-h-20 w-full rounded-md border py-2"
              />
            </div>
            <div>
              <LabelComponent
                for="correctAnswer"
                text="Alternativas"
              />
              <div class="flex flex-col space-y-2">
                <div
                  v-for="(alternatives, index) in editingQuestion.multipleChoice?.alternatives"
                  :key="index"
                  class="flex items-center space-x-2"
                >
                  <input
                    v-model="editingQuestion.multipleChoice.correctAlternative"
                    type="radio"
                    :value="index"
                    class="form-radio size-4 text-gray-600"
                  >
                  <input
                    v-model="editingQuestion.multipleChoice.alternatives[index]"
                    type="text"
                    class="w-full rounded-md border py-2"
                  >
                  <button
                    class="text-gray-500"
                    @click="removeOption(index)"
                  >
                    <Icon
                      icon="mdi:cancel-bold"
                      class="size-5"
                    />
                  </button>
                </div>
                <Button
                  @click="addAlternative(Math.max(...Object.keys(editingQuestion.multipleChoice.alternatives).map(Number)))"
                >
                  Añadir Alternativa
                </Button>
              </div>
            </div>
            <div class="flex flex-col space-y-2">
              <div>
                <LabelComponent
                  for="correctAnswer"
                  text="Dificultad"
                />
                <select
                  id="difficulty"
                  v-model="editingQuestion.difficultyText"
                  value="editingQuestion.difficultyText"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="easy">
                    Fácil
                  </option>
                  <option value="medium">
                    Media
                  </option>
                  <option value="hard">
                    Difícil
                  </option>
                </select>
              </div>
              <div>
                <LabelComponent
                  for="topic"
                  text="Tema"
                />
                <select
                  id="topic"
                  v-model="editingQuestion.topicName"
                  value="editingQuestion.topicName"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option
                    v-for="topic in topics"
                    :key="topic.id"
                  >
                    {{ topic.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex justify-between">
              <button
                @click="deleteQuestion()"
              >
                <Icon
                  icon="mdi:trash-can"
                  class="size-6 hover:text-gray-500"
                />
              </button>
              <div>
                <Button
                  class=""
                  @click="updateQuestion()"
                >
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        v-if="isCreatingQuestionModalOpen"
        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        @click.self="isCreatingQuestionModalOpen = false"
      >
        <div class="flex w-full max-w-md flex-col space-y-4 rounded-lg bg-white p-8">
          <div class="mb-4 text-2xl font-bold">
            Generador de preguntas
          </div>
          <div class=" flex flex-col items-center space-y-4">
            <div class="flex w-full flex-row space-x-4">
              <div
                class=" flex w-full flex-col space-y-2"
              >
                <LabelComponent
                  for="base"
                  text="En base a"
                />
                <select
                  id="base"
                  v-model="creatingQuestion.creatingFrom"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="question">
                    Pregunta
                  </option>
                  <option value="topic">
                    Tema
                  </option>
                </select>
              </div>
              <div class="flex w-full flex-col space-y-2">
                <LabelComponent
                  for="questionOrTopic"
                  :text="creatingQuestion.creatingFrom == 'question' ? 'Pregunta' : 'Tema'"
                />
                <select
                  v-if="creatingQuestion.creatingFrom == 'question'"
                  v-model="creatingQuestion.questionId"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option
                    v-for="question in questions"
                    :key="question.id"
                    :value="question.id"
                  >
                    {{ question.question }}
                  </option>
                </select>
                <select
                  v-if="creatingQuestion.creatingFrom == 'topic'"
                  v-model="creatingQuestion.topicId"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option
                    v-for="topic in topics"
                    :key="topic.id"
                    :value="topic.id"
                  >
                    {{ topic.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-full">
              <LabelComponent
                for="difficulty"
                text="Dificultad"
              />
              <select
                id="difficulty"
                v-model="creatingQuestion.difficulty"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="undefined">
                  Solicitar por IA
                </option>
                <option value="easy">
                  Fácil
                </option>
                <option value="medium">
                  Media
                </option>
                <option value="hard">
                  Difícil
                </option>
              </select>
            </div>
            <div>
              <LabelComponent
                for="quantity"
                text="Cantidad"
              />
              <input
                v-model="creatingQuestion.quantity"
                type="number"
                value="creatingQuestion.quantity"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
            </div>
            <div>
              <Button
                @click="generateQuestion()"
              >
                Generar preguntas
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </teacher-layout>
</template>

<script>
import { Icon } from '@iconify/vue';
import coursesApi from '@/api/courses';
import questionsApi from '@/api/questions';
import { useCourseStore } from '@/stores/courseStore';
import Button from '@/components/button.vue';
import LabelComponent from '@/components/label.vue';

export default {
  components: {
    Icon,
    LabelComponent,
    Button,
  },
  setup() {
    const courseStore = useCourseStore();
    return {
      courseStore,
    };
  },
  data() {
    return {
      courseId: this.$route.params.courseId,
      course: {},
      topics: [],
      units: [],
      filters: [],
      questions: [],
      selectedSortOption: '',
      selectedQuestionIndex: null,
      editingQuestion: {},
      isCreatingQuestionModalOpen: false,
      creatingQuestion: {},
      filterQuestions: {
        unitId: '',
        topicId: '',
        type: '',
        difficulty: '',
        generatedByAI: false,
      },
      filteredQuestions: [],
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getQuestions();
      this.getCourse();
    },
    filterQuestions: {
      handler() {
        this.filteredQuestions = this.questions.filter((question) => {
          let isValid = true;
          if (this.filterQuestions.unitId) {
            isValid = isValid && question.unit.id == this.filterQuestions.unitId;
          }
          if (this.filterQuestions.topicId) {
            isValid = isValid && question.topicId == this.filterQuestions.topicId;
          }
          if (this.filterQuestions.type) {
            isValid = isValid && question.type == this.filterQuestions.type;
          }
          if (this.filterQuestions.difficulty) {
            isValid = isValid && this.translateDifficultyToText(question.difficulty) == this.filterQuestions.difficulty;
          }
          return isValid;
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.getQuestions();
    this.getCourse();
  },
  methods: {
    goBack() {
      const courseId = this.$route.params.courseId; // Obtiene el ID del curso desde la ruta actual
      this.$router.push(`/teacher/course/${courseId}`); // Navega al curso correspondiente
    },
    async getQuestions(){
      this.questions = (await coursesApi.questions.getAll(this.courseId, {withUnit: true})).data.data;
      this.questions = this.questions.filter((item) => item.type === 'multiple_choice');
      this.filteredQuestions = this.questions;
    },
    async deleteQuestion(){
      await questionsApi.delete(this.editingQuestion.id);
      this.getQuestions();
      this.editingQuestion = {};
    },
    async getCourse() {
      this.course = (await coursesApi.getById(this.courseId, {
        withUnitsTopics: true,
      })).data.data;
      this.topics = (await coursesApi.topics.getAll(this.courseId)).data.data;
      this.units = (await coursesApi.units.getAll(this.courseId)).data.data;
    },
    async openModal(question){
      this.isCreatingQuestionModalOpen = true;
      this.creatingQuestion = {
        'creatingFrom': 'question',
        'questionId': question.id,
        'topicId': question.topicId,
        'difficulty': 'undefined',
        'quantity': 1,
      };
    },
    closeModal(){
      this.isCreatingQuestionModalOpen = false;
    },
    cleanFilteredQuestions(){
      this.filterQuestions = {
        unitId: '',
        topicId: '',
        type: '',
        difficulty: '',
        generatedByAI: false,
      };
      this.filteredQuestions = this.questions;
    },
    async updateQuestion(){
      this.editingQuestion.difficulty = this.translateDifficultyToNumber(this.editingQuestion.difficultyText);
      this.editingQuestion.topicId = this.topics.find((topic) => topic.name == this.editingQuestion.topicName).id;
      const updatedQuestion = await questionsApi.update(this.editingQuestion.id, this.editingQuestion);
      this.setEditingQuestion(updatedQuestion.data.data);
      this.getQuestions();
    },
    getEditingQuestion(){
      return this.topics.find((topic) => topic.id == this.editingQuestion.topicId).name;
    },
    removeOption(index){
      delete this.editingQuestion.multipleChoice.alternatives[index];

      const reorderedAlternatives = {};

      Object.keys(this.editingQuestion.multipleChoice.alternatives)
        .map(Number)
        .sort((a, b) => a - b)
        .forEach((key, newIndex) => {
          reorderedAlternatives[newIndex + 1] = this.editingQuestion.multipleChoice.alternatives[key];
        });
      this.editingQuestion.multipleChoice.alternatives = reorderedAlternatives;
    },
    addAlternative(alternatives){
      this.editingQuestion.multipleChoice.alternatives[alternatives + 1] = '';
    },
    setEditingQuestion(question){
      this.editingQuestion = question;
      this.editingQuestion.topicName = this.getEditingQuestion();
      this.editingQuestion.difficulty = question.difficulty;
      this.editingQuestion.difficultyText = this.translateDifficultyToText(question.difficulty);
    },
    async saveGeneratedQuestions(){
      this.getQuestions();
    },
    translateDifficultyToNumber(difficulty){
      if (difficulty == 'easy'){
        return 0.16;
      }else if (difficulty == 'medium'){
        return 0.49;
      }else if (difficulty == 'hard'){
        return 0.82;
      } else {
        return null;
      }
    },
    translateDifficultyToText(difficulty){
      if (difficulty <= 0.33){
        return 'easy';
      }else if (difficulty <= 0.66){
        return 'medium';
      }else if (difficulty <= 1){
        return 'hard';
      }else {
        return 'undefined';
      }
    },
    difficultyStyles(difficulty){
      if (difficulty <= 0.33){
        return 'bg-green-200 border-green-600 border-2';
      }else if (difficulty <= 0.66){
        return 'bg-yellow-200 border-yellow-600 border-2';
      }else{
        return 'bg-gray-200 border-gray-600 border-2';
      }
    },
    async generateQuestion(){
      this.filterQuestions = {
        unitId: '',
        topicId: '',
        type: '',
        difficulty: '',
        generatedByAI: true,
      };
      this.editingQuestion = {};
      this.creatingQuestion.difficulty = this.translateDifficultyToNumber(this.creatingQuestion.difficulty);

      if (this.creatingQuestion.creatingFrom == 'question'){
        this.filteredQuestions = (await questionsApi.generate(
          {
            topicId: this.creatingQuestion.topicId,
            questionId: this.creatingQuestion.questionId,
            difficulty: this.creatingQuestion.difficulty,
            type: 'multiple_choice',
            quantity: this.creatingQuestion.quantity,
          },
        )).data.data;
      } else {
        this.filteredQuestions = (await questionsApi.generate(
          {
            topicId: this.creatingQuestion.topicId,
            difficulty: this.creatingQuestion.difficulty,
            type: 'multiple_choice',
            quantity: this.creatingQuestion.quantity,
          },
        )).data.data;
      }
      this.closeModal();
    },
  },
};
</script>
