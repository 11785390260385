import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';

import TeacherCourseCreate from '@/views/Teacher/Course/Create.vue';
import TeacherCourseEdit from '@/views/Teacher/Course/Edit.vue';
import TeacherCourseFiles from '@/views/Teacher/Course/Files/index.vue';
import TeacherCourseUploadFiles from '@/views/Teacher/Course/Files/upload.vue';
import TeacherCourseIndex from '@/views/Teacher/Course/Index.vue';
import TeacherCourseShow from '@/views/Teacher/Course/Show.vue';
import TeacherCourseUnitEdit from '@/views/Teacher/Course/Unit/Edit.vue';
import TeacherCourseUnitTopicQuestionIndex from '@/views/Teacher/Course/Unit/Topic/Question/Index.vue';
import TeacherProfileShow from '@/views/Teacher/Profile/show.vue';

import StudentCourseIndex from '@/views/Student/Course/index.vue';
import StudentCourseShow from '@/views/Student/Course/show.vue';
import StudentCourseFilesIndex from '@/views/Student/Course/Files/index.vue';
import StudentTopicShow from '@/views/Student/Topic/show.vue';
import StudentTopicFilesIndex from '@/views/Student/Topic/Files/index.vue';
import StudentProfileShow from '@/views/Student/Profile/show.vue';
import StudentCourseWelcome from '@/views/Student/Course/welcome.vue'

import Chat from '@/views/Student/Chat/chat.vue';

import ExerciseIndex from '@/views/Student/Exercise/index.vue';
import ExerciseShow from '@/views/Student/Exercise/show.vue';
import ExerciseStats from '@/views/Student/Exercise/stats.vue';

import TeacherCourseQuestions from '@/views/Teacher/Questions.vue';


const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView,
    },
    {
      name: 'teacher',
      path: '/teacher',
      children: [
        {
          path: '/teacher/courses',
          name: 'teacher.course.index',
          component: TeacherCourseIndex,
        },
        {
          path: '/teacher/course/:courseId/edit',
          name: 'teacher.course.edit',
          component: TeacherCourseEdit,
        },
        {
          path: '/teacher/course/:courseId/files',
          name: 'teacher.course.files',
          component: TeacherCourseFiles,
        },
        {
          path: '/teacher/course/:courseId/files/upload',
          name: 'teacher.course.files.upload',
          component: TeacherCourseUploadFiles,
        },
        {
          path: '/teacher/course/create',
          name: 'teacher.course.create',
          component: TeacherCourseCreate,
        },
        {
          path: '/teacher/course/:courseId',
          name: 'teacher.course.show',
          component: TeacherCourseShow,
        },
        {
          path: '/teacher/course/:courseId/units/edit',
          name: 'teacher.course.unit.edit',
          component: TeacherCourseUnitEdit,
        },
        {
          // deprecated
          path: '/teacher/course/:courseId/units/:unitId/topics/:topicId/questions',
          name: 'teacher.course.unit.topic.question.index',
          component: TeacherCourseUnitTopicQuestionIndex,
        },
        {
          path: '/teacher/course/:courseId/questions',
          name: 'teacher.course.questions',
          component: TeacherCourseQuestions,
        },
        {
          path: '/teacher/profile',
          name: 'teacher.profile.show',
          component: TeacherProfileShow,
        },
      ],
    },
    {
      name: 'student',
      path: '/student',
      children: [
        {
          path: '/student/course/:courseId/welcome',
          name: 'student.course.welcome',
          component: StudentCourseWelcome,
        },
        {
          path: '/student/course/:courseId',
          name: 'student.course.show',
          component: StudentCourseShow,
        },
        {
          path: '/student/courses',
          name: 'student.course.index',
          component: StudentCourseIndex,
        },
        {
          path: '/student/courses/:courseId/files',
          name: 'student.course.files.index',
          component: StudentCourseFilesIndex,
        },
        {
          path: '/student/course/:courseId/unit/:unitId/topic/:topicId',
          name: 'student.course.unit.topic.show',
          component: StudentTopicShow,
          props: true,
        },
        {
          path: '/student/course/:courseId/unit/:unitId/topic/:topicId/files',
          name: 'student.course.unit.topic.files.index',
          component: StudentTopicFilesIndex,
          props: true,
        },
        {
          path: 'chat/course/:courseId',
          name: 'student.chat',
          component: Chat,
        },
        {
          path: 'chat/course/:courseId/chat/:chatId',
          name: 'student.chat.show',
          component: Chat,
        },
        {
          path: 'course/:courseId/exercise',
          name: 'student.exercise.index',
          component: ExerciseIndex,
        },
        {
          path: 'course/:courseId/exercise/:questionnaireId',
          name: 'student.exercise.show',
          component: ExerciseShow,
        },
        {
          path: 'course/:courseId/exercise/:questionnaireId/stats',
          name: 'student.exercise.stats',
          component: ExerciseStats,
        },
        {
          path: '/student/profile',
          name: 'student.profile.show',
          component: StudentProfileShow,
        },

      ],
    },
  ],
});

export default router;
