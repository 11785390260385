<template>
  <teacher-layout>
    <main class="px-4 py-16 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-7xl">
        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h1 class="text-3xl font-bold leading-tight text-gray-900">
              {{ course?.name }}
            </h1>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              {{ course?.description }}
            </p>
          </div>

          <div class="border-t border-gray-200 px-4 py-5 sm:p-6">
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div
                class="group cursor-pointer rounded-lg bg-purple-100 p-6 shadow-md transition-all duration-300 hover:shadow-lg"
                @click="editUnits"
              >
                <div class="flex items-center justify-between">
                  <div>
                    <h3 class="mb-2 text-lg font-medium text-gray-900 transition-colors duration-300 group-hover:text-purple-700">
                      Modificar Visibilidad
                    </h3>
                    <p class="text-sm text-gray-500 transition-colors duration-300 group-hover:text-purple-600">
                      Edita lo que pueden ver los alumnos del curso
                    </p>
                  </div>
                  <Icon
                    icon="mdi:pencil"
                    class="size-6 text-gray-400 group-hover:text-purple-600"
                  />
                </div>
              </div>

              <div
                class="group cursor-pointer rounded-lg bg-purple-100 p-6 shadow-md transition-all duration-300 hover:shadow-lg"
                @click="uploadFiles"
              >
                <div class="flex items-center justify-between">
                  <div>
                    <h3 class="mb-2 text-lg font-medium text-gray-900 transition-colors duration-300 group-hover:text-purple-700">
                      Subir Archivos
                    </h3>
                    <p class="text-sm text-gray-500 transition-colors duration-300 group-hover:text-purple-600">
                      Añade materiales al curso
                    </p>
                  </div>
                  <Icon
                    icon="mdi:upload"
                    class="size-6 text-gray-400 group-hover:text-purple-600"
                  />
                </div>
              </div>

              <div
                class="group cursor-pointer rounded-lg bg-purple-100 p-6 shadow-md transition-all duration-300 hover:shadow-lg"
                @click="toggleQuestions"
              >
                <div class="flex items-center justify-between">
                  <div>
                    <h3 class="mb-2 text-lg font-medium text-gray-900 transition-colors duration-300 group-hover:text-purple-700">
                      Agregar Preguntas
                    </h3>
                    <p class="text-sm text-gray-500 transition-colors duration-300 group-hover:text-purple-600">
                      Añade ejercicios para el curso
                    </p>
                  </div>
                  <Icon
                    :icon="showQuestions ? 'mdi:chevron-up' : 'mdi:help-circle'"
                    class="size-6 text-gray-400 group-hover:text-purple-600"
                  />
                </div>
              </div>
              <div
                class="group cursor-pointer rounded-lg bg-purple-100 p-6 shadow-md transition-all duration-300 hover:shadow-lg"
                @click="editHierarchy"
              >
                <div class="flex items-center justify-between">
                  <div>
                    <h3 class="mb-2 text-lg font-medium text-gray-900 transition-colors duration-300 group-hover:text-purple-700">
                      Modificar Jerarquía
                    </h3>
                    <p class="text-sm text-gray-500 transition-colors duration-300 group-hover:text-purple-600">
                      Edita la estructura del curso (esto aun no funciona, falta esperar una pr que arregle la vista de jerarquia)
                    </p>
                  </div>
                  <Icon
                    icon="icon-park-solid:blocks-and-arrows"
                    class="size-6 text-gray-400 group-hover:text-purple-600"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="showQuestions"
            class="border-t border-gray-200 px-4 py-5 sm:px-6"
          >
            <h2 class="text-lg font-medium text-gray-900">
              Unidades
            </h2>
            <ul class="mt-4 space-y-4">
              <li
                v-for="unit in course.units"
                :key="unit.id"
                class="rounded-lg bg-gray-50 p-4"
              >
                <h3 class="text-lg font-medium text-gray-900">
                  {{ unit.name }}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  {{ unit.description }}
                </p>
                <div class="mt-4">
                  <h4 class="text-sm font-medium text-gray-700">
                    Temas:
                  </h4>
                  <ul class="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                    <li
                      v-for="topic in unit.topics"
                      :key="topic.id"
                      class="rounded-md bg-white p-3 shadow-sm"
                    >
                      <h5 class="text-sm font-medium text-gray-900">
                        {{ topic.name }}
                      </h5>
                      <p class="mt-1 text-xs text-gray-500">
                        {{ topic.description }}
                      </p>
                      <button
                        class="mt-2 text-xs text-purple-600 transition-colors duration-200 hover:text-purple-800"
                        @click="addTopicQuestions(course.id, unit.id, topic.id)"
                      >
                        Añadir preguntas
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </teacher-layout>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import coursesApi from '@/api/courses';
import { Icon } from '@iconify/vue';

const route = useRoute();
const router = useRouter();
const course = ref({});
const showQuestions = ref(false);

const getCourse = async () => {
  const response = await coursesApi.getById(route.params.courseId, {
    withUnits: true,
    withUnitsTopics: true,
  });
  course.value = response.data.data;
};

const editUnits = () => {
  router.push({ name: 'teacher.course.unit.edit', params: { courseId: course.value.id } });
};

const uploadFiles = () => {
  router.push({ name: 'teacher.course.files.upload', params: { courseId: course.value.id } });
};

const toggleQuestions = () => {
  showQuestions.value = !showQuestions.value;
};

const addTopicQuestions = (courseId, unitId, topicId) => {
  router.push({
    name: 'teacher.course.unit.topic.question.index',
    params: { courseId, unitId, topicId },
  });
};

const editHierarchy = () => {
  router.push({ name: 'teacher.course.show', params: { courseId: course.value.id } });
};

onMounted(getCourse);

watch(() => route.params.courseId, getCourse);
</script>
