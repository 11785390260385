<template>
  <teacher-layout>
    <main class="p-8">
      <div class="mx-auto max-w-6xl">
        <Title
          text="Cursos del profesor *user.name*"
          class="mb-8 text-5xl font-bold text-black"
        />
        <h2 class="mb-4 text-2xl font-semibold text-black">
          Cursos:
        </h2>
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div
            v-for="(course, index) in courses"
            :key="course.id"
            class="opacity-0 transition-all duration-500 ease-out"
            :class="{
              'translate-y-0 opacity-100': true,
            }"
          >
            <div
              class="flex overflow-hidden rounded-lg bg-white shadow-lg transition duration-300 ease-in-out hover:-translate-y-1 hover:shadow-xl"
            >
              <!-- Enlace para toda la zona morada -->
              <a
                href="#"
                class="flex w-2/5 flex-col justify-between bg-indigo-900 p-6"
                @click.prevent="selectCourse(course)"
              >
                <div>
                  <h3 class="mb-2 text-xl font-bold text-white">
                    {{ course.name }}
                  </h3>

                </div>
              </a>

              <!-- Zona blanca no clickeable -->
              <div class="flex w-3/5 flex-col justify-between p-6">
                <div>
                  <h4 class="mb-2 text-xl font-semibold">
                    {{ course.name }}
                  </h4>
                  <p class="mb-4 text-sm text-gray-600">
                    {{ course.description || 'Sin descripción' }}
                  </p>
                </div>
                <Button
                  class="bg-purple w-full rounded-lg px-4 py-2 font-semibold text-white transition duration-300 ease-in-out hover:bg-purple-700"
                  @click="selectCourse(course)"
                >
                  Ir al curso
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </teacher-layout>
</template>

<script>
import Title from '@/components/title.vue';
import Button from '@/components/button.vue';
import coursesApi from '@/api/courses';
import { useCourseStore } from '@/stores/courseStore';

export default {
  components: {
    Title,
    Button,
  },
  setup() {
    const courseStore = useCourseStore();

    return {
      courseStore,
    };
  },
  data() {
    return {
      courses: [],
    };
  },
  async mounted() {
    const response = await coursesApi.getAll();
    this.courses = response.data.data;
  },
  methods: {
    selectCourse(course) {
      this.courseStore.setCourse(course);
      this.$router.push({ name: 'teacher.course.show', params: { courseId: course.id } });
    },
  },
};
</script>
