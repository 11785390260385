<template>
  <teacher-layout>
    <main class="bg-purple-50">
      <div class="container mx-auto space-y-8 px-4 py-8 pb-20 ">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
        <!-- Header section with title and upload button -->
        <div class="mb-8 flex items-center justify-between">
          <Title
            :text="`Archivos del curso ${course.name}`"
            class="text-3xl font-bold text-gray-800"
          />
          <router-link :to="{name:'teacher.course.files.upload',params:{courseId:courseId}}">
            <ButtonComponent
              class="px-6 py-3 text-lg"
            >
              <Icon
                icon="mdi:plus"
                class="mr-2"
              />
              Subir Archivo
            </ButtonComponent>
          </router-link>
        </div>

        <!-- Sticky container for filters -->
        <div class="sticky top-0 z-10 bg-white p-5 shadow-md">
          <div class="flex flex-wrap items-end gap-4">
            <div class="min-w-[200px] grow">
              <LabelComponent
                for="Units"
                text="Unidades"
                class="mb-1 block text-sm font-medium text-gray-700"
              />
              <select
                id="Units"
                v-model="filterFiles.unitId"
                class="block h-[38px] w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
              >
                <option
                  v-for="unit in units"
                  :key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div class="min-w-[200px] grow">
              <LabelComponent
                for="Topics"
                text="Temas"
                class="mb-1 block text-sm font-medium text-gray-700"
              />
              <select
                id="Topics"
                v-model="filterFiles.topicId"
                class="block h-[38px] w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
              >
                <option
                  v-for="topic in topics"
                  :key="topic.id"
                  :value="topic.id"
                >
                  {{ topic.name }}
                </option>
              </select>
            </div>
            <div class="mb-2 flex gap-2">
              <a
                class="cursor-pointer font-bold text-purple-700"
                @click="cleanFilteredFiles()"
              >
                Limpiar filtros
              </a>
            </div>
          </div>
        </div>

        <div class="overflow-hidden rounded-lg bg-white shadow-md">
          <div class="grid grid-cols-12 border bg-gray-100 p-4 font-semibold text-gray-700">
            <div class="col-span-1" />
            <div class="col-span-4">
              Nombre
            </div>
            <div class="col-span-1" />
            <div class="col-span-4">
              Tema/unidad
            </div>
            <div class="col-span-2">
              Acciones
            </div>
          </div>
          <div class="divide-y divide-gray-200">
            <div
              v-for="file in filteredFiles"
              :key="file.id"
              class="grid grid-cols-12 items-center p-4 transition-colors hover:bg-purple-50"
            >
              <div class="col-span-1">
                <input
                  type="checkbox"
                  :checked="selectedFiles.includes(file.id)"
                  class="rounded border-gray-300 text-purple-700 shadow-sm focus:border-purple-400 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  @change="changeSelectedFiles(file)"
                >
              </div>
              <div class="col-span-4 truncate">
                {{ file.originalName }}
              </div>
              <div class="col-span-1" /> <!-- Added spacer column -->
              <div class="col-span-4">
                <!-- Reduced from col-span-5 to col-span-4 -->
                <div
                  v-for="topic in file.topics"
                  :key="topic.id"
                  class="text-gray-600 sm:text-xs md:text-base"
                >
                  {{ topic.name }} / {{ topic.unit.name }}
                </div>
              </div>
              <div class="col-span-2 flex justify-end">
                <button
                  class="font-medium text-purple-700 hover:text-purple-800"
                  @click="downloadFile(file)"
                >
                  Descargar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selectedFiles.length > 1"
        class="fixed inset-x-0 bottom-0 bg-white p-4 shadow-md"
      >
        <div class="container mx-auto flex justify-end">
          <ButtonComponent
            class="bg-gray-600 text-white hover:bg-gray-700"
            @click="downloadFiles"
          >
            Descargar {{ selectedFiles.length }} archivos seleccionados
          </ButtonComponent>
        </div>
      </div>
    </main>
  </teacher-layout>
</template>


<script>
import { reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';
import coursesApi from '@/api/courses';
import filesApi from '@/api/files';
import Title from '@/components/title.vue';
import ButtonComponent from '@/components/button.vue';
import LabelComponent from '@/components/label.vue';
import { RouterLink } from 'vue-router';
import chatsApi from '@/api/chats';

export default {
  components: {
    Icon,
    Title,
    ButtonComponent,
    LabelComponent,
    RouterLink,
  },
  data() {
    return {
      files: [],
      selectedFiles: [],
      course: {},
      topics: [],
      units: [],
      filterFiles: {
        unitId: null,
        topicId: null,
      },
      filteredFiles: [],
      courseId: this.$route.params.courseId,
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
      this.getFiles();
    },
    filterFiles:{
      handler(){
        this.filteredFiles = this.files.filter((file) => {
          let topics = file.topics.map(topic => topic.id);
          let units = file.topics.map(topic => topic.unitId);
          let isValid = true;
          if (this.filterFiles.unitId) {
            isValid = isValid && units.includes(this.filterFiles.unitId);
          }
          if (this.filterFiles.topicId) {
            isValid = isValid && topics.includes(this.filterFiles.topicId);
          }
          return isValid;
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.getFiles();
    this.getCourse();
    this.topics = (await coursesApi.topics.getAll(this.courseId)).data.data;
    this.units = (await coursesApi.units.getAll(this.courseId)).data.data;
  },
  methods: {
    goBack() {
      const courseId = this.$route.params.courseId; // Obtiene el ID del curso desde la ruta actual
      this.$router.push(`/teacher/course/${courseId}`); // Navega al curso correspondiente
    },
    async downloadFile(file) {
      filesApi.download(file.id).then (response => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.filename;
        link.click();
      });
    },
    async downloadFiles() {
      filesApi.downloadMultiple(this.selectedFiles).then (response => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'files.zip';
        link.click();
      });
    },
    async getFiles() {
      this.files = (await coursesApi.files.getAll(this.courseId)).data.data;
      this.filteredFiles = this.files;
    },
    async getCourse() {
      this.course = (await coursesApi.getById(this.courseId)).data.data;
    },
    cleanFilteredFiles() {
      this.filterFiles.unitId = null;
      this.filterFiles.topicId = null;
      this.filteredFiles = this.files;
    },
    changeSelectedFiles(file) {
      if (this.selectedFiles.includes(file.id)) {
        this.selectedFiles = this.selectedFiles.filter(f => f !== file.id);
      } else {
        this.selectedFiles.push(file.id);
      }
    },
  },
};
</script>
