<template>
  <teacher-layout>
    <main>
      <div class="container mx-auto p-10">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
        <div class=" rounded-lg bg-white p-8 shadow">
          <div class="mb-6 text-lg">
            <label class="text-3xl font-bold text-black">Curso {{ course?.name }}</label>
          </div>
          <div class="flex">
            <!-- Parte izquierda -->
            <!-- Tabla de unidades -->
            <div class="w-1/3 border-r pr-4">
              <h2 class="mb-4 text-2xl font-bold">
                Unidades
              </h2>
              <ul>
                <li
                  v-for="unit in course.units"
                  :key="unit.id"
                  class="cursor-pointer rounded px-4 py-2 hover:bg-gray-100"
                  :class="{ 'bg-blue-100': selectedUnit === unit }"
                  @click="selectUnit(unit)"
                >
                  <div class="flex items-center justify-between">
                    <span :class="hiddenClass(unit)">{{ unit.name }}</span>
                    <Icon
                      v-if="unit.isVisible"
                      icon="mdi:eye-outline"
                      class="cursor-pointer text-lg"
                      @click.stop="hideUnit(unit)"
                    />
                    <Icon
                      v-else
                      icon="mdi:eye-off-outline"
                      class="cursor-pointer text-lg"
                      @click.stop="hideUnit(unit)"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <!-- Parte derecha -->
            <!-- Lista de temas -->
            <div class="w-2/3 pl-4">
              <h2 class="mb-4 text-2xl font-bold">
                Temas
              </h2>
              <div v-if="selectedUnit">
                <div
                  v-for="topic in selectedUnit.topics"
                  :key="topic.id"
                  class="py-2"
                >
                  <div :class="`flex justify-between items-center ${hiddenClass(selectedUnit, topic)}`">
                    <span>{{ topic.name }}</span>
                    <Icon
                      v-if="topic.isVisible && selectedUnit.isVisible"
                      icon="mdi:eye-outline"
                      class="cursor-pointer text-lg"
                      @click="hideTopic(selectedUnit, topic)"
                    />
                    <Icon
                      v-else
                      icon="mdi:eye-off-outline"
                      class="cursor-pointer text-lg"
                      @click="hideTopic(selectedUnit, topic)"
                    />
                  </div>
                </div>
              </div>
              <div
                v-else
                class="text-gray-500"
              >
                Selecciona una unidad para ver sus temas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </teacher-layout>
</template>

<script>
import coursesApi from '@/api/courses';
import unitsApi from '@/api/units';
import topicApi from '@/api/topics';

import { Icon } from '@iconify/vue';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      course: {},
      units: [],
      topics: [],
      selectedUnit: null,
    };
  },
  async mounted() {
    this.course = (await coursesApi.getById(this.$route.params.courseId, {
      withUnits: true,
      withUnitsTopics: true,
    })).data.data;
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Esto navega a la página anterior
    },
    selectUnit(unit) {
      this.selectedUnit = unit;
    },
    async hideUnit(unit) {
      await unitsApi.update(unit.id, {
        isVisible: !unit.isVisible,
      });
      this.refreshCourse();
    },
    async hideTopic(unit, topic) {
      await topicApi.update(topic.id, {
        isVisible: !topic.isVisible,
      });
      this.refreshCourse();
    },
    hiddenClass(unit, topic = null) {
      if (unit.isVisible && (!topic || topic.isVisible)) {
        return 'text-gray-800';
      } else {
        return 'text-gray-500';
      }
    },
    async refreshCourse() {
      this.course = (await coursesApi.getById(this.$route.params.courseId, {
        withUnits: true,
        withUnitsTopics: true,
      })).data.data;
      if (this.selectedUnit) {
        this.selectedUnit = this.course.units.find(u => u.id === this.selectedUnit.id);
      }
    },
  },
};
</script>
