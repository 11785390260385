<template>
  <student-layout class="flex h-screen flex-col">
    <div class="flex grow overflow-hidden">
      <aside class="flex w-64 shrink-0 flex-col overflow-hidden bg-purple-50 shadow-md">
        <div class="p-4">
          <button
            class="mb-6 flex w-full items-center gap-2 rounded-md py-2 text-purple-700"
            @click="goBack"
          >
            <Icon
              icon="mdi:arrow-left"
              class="size-5"
            />
            Volver atrás
          </button>

          <Button

            @click="createNewChat()"
          >
            <Icon
              icon="mdi:plus"
              class="mr-2"
            /> Nuevo Chat
          </Button>
        </div>
        <div class="grow overflow-y-auto">
          <div class="space-y-2 px-4">
            <div
              v-for="chat in chats"
              :key="chat.id"
              :class="`flex w-full justify-between items-center ${selectedChatId == chat.id ? 'bg-purple-100 text-gray-700' : 'hover:bg-gray-100'} px-4 py-2 text-left rounded transition duration-300`"
            >
              <div
                class="grow cursor-pointer"
                @click="selectedChatId = chat.id"
              >
                Chat {{ chat.id }}
              </div>
              <button
                class="text-gray-500 transition duration-300 hover:text-gray-700"
                @click="deleteChat(chat.id)"
              >
                <Icon icon="mdi:delete" />
              </button>
            </div>
          </div>
        </div>
      </aside>
      <main class="flex grow overflow-hidden">
        <div
          v-if="selectedChatId"
          class="flex grow flex-col bg-purple-50 shadow-md"
        >
          <!-- SECCION DEL ARCHIVO -->
          <div class="hidden w-1/2 flex-col bg-white shadow-md">
            <div class="border-b p-4">
              <div class="flex items-center font-semibold text-gray-700">
                <Icon
                  icon="mdi:file-document-outline"
                  class="mr-2"
                /> file.md
              </div>
            </div>
            <div class="grow overflow-y-auto p-4">
              <p>Contenido del archivo...</p>
            </div>
          </div>

          <div class="flex grow flex-col bg-purple-50 shadow-md">
            <!-- SECCION DEL CHAT -->
            <div class="grow overflow-y-auto p-6">
              <div
                v-for="(message, index) in chat.chatHistory"
                :key="index"
              >
                <div
                  v-if="message.fromSystem"
                  class="mb-4 flex flex-col items-start space-y-2"
                >
                  <div class="flex items-start space-x-2">
                    <div class="flex size-8 items-center justify-center rounded-full bg-gray-500 text-white">
                      <Icon icon="mdi:robot" />
                    </div>
                    <div class="max-w-[80%] rounded-lg bg-gray-50 p-3">
                      <p class="text-gray-800">
                        {{ message.message }}
                      </p>
                      <Icon
                        v-if="message.loading"
                        icon="svg-spinners:3-dots-bounce"
                      />
                    </div>
                  </div>
                  <div class="ml-10 flex space-x-2">
                    <button>
                      <Icon icon="mdi:thumb-up" />
                    </button>
                    <button>
                      <Icon icon="mdi:thumb-down" />
                    </button>
                  </div>
                </div>
                <div
                  v-else
                  class="mb-4 flex items-start justify-end space-x-2"
                >
                  <div class="max-w-[80%] rounded-lg bg-gray-100 p-3">
                    <p class="text-gray-800">
                      {{ message.message }}
                    </p>
                  </div>
                  <div class="flex size-8 items-center justify-center rounded-full bg-gray-300">
                    <Icon icon="mdi:account" />
                  </div>
                </div>
              </div>
            </div>

            <!-- input del chat -->
            <div class="border-t p-2">
              <div class="flex max-w-full space-x-2">
                <input
                  v-model="currentMessage"
                  type="text"
                  class="w-full grow rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  placeholder="Escribe tu mensaje..."
                  @keypress.enter="sendChatMessage"
                >
                <div>
                  <Button
                    class="h-full"
                    @click="sendChatMessage"
                  >
                    <Icon
                      icon="mdi:send"
                      class="mr-2"
                    /> Enviar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex grow items-center justify-center bg-purple-50"
        >
          <div class="text-center">
            <Icon
              icon="mdi:chat-outline"
              class="mx-auto text-6xl text-gray-400"
            />
            <h2 class="mt-4 text-2xl font-semibold text-gray-700">
              Bienvenido al Chat
            </h2>
            <p class="mt-2 text-gray-500">
              Selecciona un chat existente o crea uno nuevo para comenzar
            </p>
          </div>
        </div>
      </main>
    </div>

    <!-- Modal -->
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 transition-opacity duration-300"
      :class="{ 'opacity-0': !isModalOpen, 'opacity-100': isModalOpen }"
    >
      <div
        class="relative w-full max-w-md rounded-lg bg-white shadow-xl transition-transform duration-300"
        :class="{ 'scale-95': !isModalOpen, 'scale-100': isModalOpen }"
        style="max-height: 80vh"
      >
        <div class="sticky top-0 z-10 rounded-t-lg bg-gray-500 p-6 text-white">
          <h2 class="text-xl font-semibold">
            Crear nuevo chat basado en:
          </h2>
          <button
            class="absolute right-3 top-3 text-white transition duration-300 hover:text-gray-200"
            @click="closeModal"
          >
            <Icon
              icon="mdi:close"
              class="text-2xl"
            />
          </button>
        </div>

        <!-- Contenido desplazable -->
        <div
          class="overflow-y-auto"
          style="max-height: calc(80vh - 150px);"
        >
          <div class="space-y-2 p-6">
            <div
              v-for="topic in topics"
              :key="topic.id"
              class="flex items-center justify-between rounded-lg bg-gray-50 p-3 transition duration-300 hover:bg-gray-100"
            >
              <span>{{ topic.name }}</span>
              <button class="text-gray-500 transition duration-300 hover:text-gray-700">
                <Icon icon="mdi:chevron-down" />
              </button>
            </div>
          </div>
        </div>

        <!-- Botón Crear chat -->
        <div class="flex justify-end border-t p-6">
          <button
            class="flex items-center rounded-lg bg-gray-500 px-6 py-2 text-white transition duration-300 hover:bg-gray-600"
            @click="createNewChat"
          >
            <Icon
              icon="mdi:plus"
              class="mr-2"
            /> Crear chat
          </button>
        </div>
      </div>
    </div>
  </student-layout>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';
import coursesApi from '@/api/courses';
import chatsApi from '@/api/chats';
import Button from '@/components/button.vue';

export default {
  name: 'ChatInterface',
  components: {
    Icon,
    Button,
  },
  setup() {
    const route = useRoute();
    const isModalOpen = ref(false);
    const chats = ref([]);
    const chat = ref({});
    const course = ref({});
    const topics = ref([]);
    const files = ref([]);
    const currentMessage = ref('');
    const selectedChatId = ref(null);
    const courseId = ref(route.params.courseId);

    const getCourse = async () => {
      const response = await coursesApi.getById(courseId.value, {
        withUnitsTopics: true,
      });
      course.value = response.data.data;
      topics.value = course.value.units.reduce((acc, unit) => {
        return acc.concat(unit.topics);
      }, []);
      files.value = (await coursesApi.files.getAll(courseId.value)).data.data;
      chats.value = (await coursesApi.chats.getAll(courseId.value)).data.data;
    };

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const getChat = async () => {
      const response = await chatsApi.getById(selectedChatId.value);
      chat.value = response.data.data;
    };

    const sendChatMessage = async () => {
      if (!currentMessage.value.trim()) return;
      const message = currentMessage.value;
      currentMessage.value = '';
      chat.value.chatHistory.push({"date": "", "fromSystem": false, "message":message}); // Se muestra el mensaje en el chat sin esperar la respuesta
      chat.value.chatHistory.push({"date": "", "fromSystem": true, "message":'', loading: true}); // Se muestra un icono de loading
      const response = await chatsApi.messages.create(chat.value.id, { message: message });
      chat.value = response.data.data;
    };

    const createNewChat = async () => {
      const response = await coursesApi.chats.create(courseId.value);
      chat.value = response.data.data;
      closeModal();
      selectedChatId.value = chat.value.id;
      chats.value = (await coursesApi.chats.getAll(courseId.value)).data.data;
    };

    const deleteChat = async (chatId) => {
      await chatsApi.delete(chatId);
      chats.value = (await coursesApi.chats.getAll(courseId.value)).data.data;
    };


    onMounted(() => {
      selectedChatId.value = route.params.chatId;
      getCourse();
    });

    watch(() => route.params.courseId, (newCourseId) => {
      courseId.value = newCourseId;
      getCourse();
    });

    watch(selectedChatId, (newChatId) => {
      if (newChatId) {
        getChat();
      }
    });

    return {
      isModalOpen,
      chats,
      chat,
      course,
      topics,
      files,
      currentMessage,
      selectedChatId,
      courseId,
      openModal,
      closeModal,
      getChat,
      sendChatMessage,
      createNewChat,
      deleteChat,
    };
  },
  methods: {
    goBack() {
      const courseId = this.$route.params.courseId; // Obtiene el ID del curso desde la ruta actual
      this.$router.push(`/student/course/${courseId}/welcome`); // Navega a la página de bienvenida
    },
  },
};
</script>
