<template>
  <div class="flex min-h-screen items-center justify-center bg-purple-50">
    <div class="w-full max-w-md">
      <transition
        appear
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div class="rounded-lg bg-white p-8 shadow-2xl">
          <h1 class="mb-6 text-center text-3xl font-bold text-purple-700">
            Únete a RANNER
          </h1>
          <form
            class="space-y-4"
            @submit.prevent="handleSubmit"
          >
            <transition-group
              enter-active-class="transition duration-300 ease-out"
              enter-from-class="transform -translate-x-4 opacity-0"
              enter-to-class="transform translate-x-0 opacity-100"
              leave-active-class="transition duration-200 ease-in"
              leave-from-class="transform translate-x-0 opacity-100"
              leave-to-class="transform -translate-x-4 opacity-0"
            >
              <div key="name">
                <label
                  for="name"
                  class="block text-sm font-medium text-purple-700"
                >Name</label>
                <input
                  id="name"
                  v-model="name"
                  type="text"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
              <div key="email">
                <label
                  for="email"
                  class="block text-sm font-medium text-purple-700"
                >Email</label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
              <div key="password">
                <label
                  for="password"
                  class="block text-sm font-medium text-purple-700"
                >Password</label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
              <div key="passwordConfirmation">
                <label
                  for="passwordConfirmation"
                  class="block text-sm font-medium text-purple-700"
                >Confirm Password</label>
                <input
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  type="password"
                  required
                  class="mt-1 block w-full rounded-md border-purple-300 shadow-sm transition duration-300 ease-in-out hover:border-purple-400 focus:border-purple-500 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                >
              </div>
            </transition-group>
            <button
              type="submit"
              :disabled="isLoading"
              class="w-full rounded-md bg-gradient-to-r from-purple-500 to-purple-700 px-4 py-2 text-white transition duration-300 ease-in-out hover:scale-105 hover:from-purple-600 hover:to-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 active:scale-95 disabled:opacity-50"
            >
              {{ isLoading ? 'Registrándote...' : 'Regístrate' }}
            </button>
          </form>
          <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform -translate-y-2 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-2 opacity-0"
          >
            <div
              v-if="formResponse && formResponse.data"
              class="mt-4 text-center text-red-600"
            >
              <p v-if="formResponse.data.name">
                {{ translateError(formResponse.data.name[0]) }}
              </p>
              <p v-if="formResponse.data.email">
                {{ translateError(formResponse.data.email[0]) }}
              </p>
              <p v-if="formResponse.data.password">
                {{ translateError(formResponse.data.password[0]) }}
              </p>
              <p v-if="formResponse.data.password_confirmation">
                {{ translateError(formResponse.data.password_confirmation[0]) }}
              </p>
              <p v-if="formResponse.data.message">
                {{ translateError(formResponse.data.message) }}
              </p>
            </div>
          </transition>
        </div>
      </transition>
      <transition
        appear
        enter-active-class="transition duration-300 delay-300 ease-out"
        enter-from-class="transform -translate-y-2 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-2 opacity-0"
      >
        <p class="mt-4 text-center text-sm text-purple-600">
          ¿Ya tienes cuenta?
          <router-link
            to="/login"
            class="font-medium text-purple-700 transition duration-300 ease-in-out hover:text-purple-800"
          >
            Inicia sesión
          </router-link>
        </p>
      </transition>
    </div>
  </div>
</template>
<script>
import usersApi from '@/api/users';
import { useUserStore } from '@/stores/userStore';

export default {
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      formResponse: '',
      errorTranslations: {
        'The email has already been taken.': 'Este correo electrónico ya está en uso.',
        'The password field must be at least 8 characters.': 'La contraseña debe tener al menos 8 caracteres.',
        'The email field is required.': 'El campo de correo electrónico es obligatorio.',
        'The password field is required.': 'El campo de contraseña es obligatorio.',
        'The name field is required.': 'El campo de nombre es obligatorio.',
        'The password field confirmation does not match.': 'La confirmación de contraseña no coincide.',
      },
      user: null,
    };
  },
  async mounted(){
    usersApi.csrfCookie();
    if(this.userStore.user.id){
      this.$router.push('/');
    }else{
      await usersApi.logout();
      this.userStore.clearUser();
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.formResponse = await usersApi.register({
          name: this.name,
          email: this.email,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        });
        this.formResponse = 'User registered successfully';
        this.user = (await usersApi.getSession()).data;
        this.userStore.setUser(this.user.id, this.user.name);
        this.$router.push('/');
      }
      catch (error) {
        this.formResponse = error.response;
      }
    },
    translateError(error) {
      return this.errorTranslations[error] || error;
    },
  },
};
</script>
