<template>
  <div
    id="toast"
    class="fixed inset-x-2/4 bottom-12 z-50 -ml-32 w-64 rounded-xl border p-4 text-center drop-shadow-lg"
    :class="[{'hidden': !show}, state, animation]"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  data(){
    return {
      text: ' ',
      show: false,
      state: '',
      success: 'bg-green-100 text-lime-600 border-lime-500',
      warning: 'bg-yellow-100 text-yellow-600 border-amber-500',
      error: 'bg-red-100 text-red-600 border-red-500',
      animation: 'animate-fadein',
    };
  },
  methods:{
    showMessage(msg, state){
      this.text = msg;

      if(state == "success") this.state = this.success;
      else if(state == "warning") this.state = this.warning;
      else if(state == "error") this.state = this.error;
      else this.state = '';

      this.show = true;
      setTimeout(()=>{
        this.animation = 'animate-fadeout';
        setTimeout(()=>{this.show = false;}, 600); // 0.6 segundos (ya terminado el fadeout)
      }, 2500); // 2.5 segundos

      this.animation = 'animate-fadein';
    },
  },
};
</script>
