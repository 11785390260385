import api from './index';

export default {
  getAll(params) {
    return api.get('/api/units', { params });
  },
  getById(id, params) {
    return api.get(`/api/units/${id}`, { params });
  },
  update(id, data) {
    return api.patch(`/api/units/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/units/${id}`);
  },
  topics: {
    getAll(unitId) {
      return api.get(`/api/units/${unitId}/topics`);
    },
    create(unitId, data) {
      return api.post(`/api/units/${unitId}/topics`, data);
    },
  },
};
