<template>
  <student-layout>
    <main class="">
      <div class="container mx-auto px-4 py-8">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
        <div class="flex flex-col gap-8 lg:flex-row">
          <div class="w-full shrink-0 lg:w-1/4 xl:w-1/5">
            <div class="sticky top-24">
              <Sidebar
                :units="units"
                :course-name="course.name"
                :expanded-unit="expandedUnit"
                @toggle-unit="toggleUnit"
                @show-topic="showTopic"
              />
              <router-link
                :to="{ name: 'student.course.files.index', params: { id: course.id } }"
                class="mt-4 block"
              >
                <Button
                  class="w-full bg-gray-600 text-white hover:bg-purple-700"
                >
                  Ver archivos del curso
                </Button>
              </router-link>
              <button
                @click="downloadGuide"
              >
                Descargar libro guia
              </button>
            </div>
          </div>

          <div class="w-full lg:w-3/4 xl:w-4/5">
            <div class="rounded-lg ">
              <div
                v-if="selectedUnit && selectedTopic"
              >
                <div class="mb-8 flex flex-col space-y-4 rounded-lg bg-white p-6 align-middle">
                  <Title
                    :text="`${selectedUnit.name} > ${selectedTopic.name}`"
                    class="mb-0 text-2xl text-gray-700"
                  />
                  <div v-if="selectedTopic.description">
                    <p class="text-gray-600">
                      {{ selectedTopic.description }}
                    </p>
                  </div>
                </div>

                <div class="mb-8 rounded-lg bg-white p-6 shadow-md">
                  <div
                    class="prose max-w-none text-gray-700"
                    v-html="topicContent"
                  />
                </div>

                <router-link
                  :to="{ name: 'student.course.unit.topic.files.index', params: { courseId: course.id, unitId: selectedUnit.id, topicId: selectedTopic.id } }"
                  class="inline-block"
                >
                  <Button>
                    Ver referencias
                  </Button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </student-layout>
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import { marked } from 'marked';
import questionsApi from '@/api/questions';
import Sidebar from '@/components/sidebar.vue';
import Title from '@/components/title.vue';
import Button from '@/components/button.vue';

export default {
  components: {
    Sidebar,
    Title,
    Button,
  },
  data() {
    return {
      course: {},
      units: [],
      expandedUnit: null,
      selectedUnit: null,
      selectedTopic: null,
      selectedStudent: 11,
      currentQuestion: {},
      currentAnswer: '',
      finalAnswer: null,
      localFeedback: null,
      generalFeedback: null,
      output: null,
      topicContent: '',
      currentQuestionContent: '',
      timings: {
        startReadQuestion: null,
        startSendResponse: null,
      },
      courseId: this.$route.params.courseId,
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
    },
  },
  async mounted() {
    this.getCourse();
    const courseName = this.course?.name || '';
    document.title = 'Curso ' + courseName;
  },
  methods: {
    goBack() {
      const courseId = this.$route.params.courseId; // Obtiene el ID del curso desde la ruta actual
      this.$router.push(`/student/course/${courseId}/welcome`); // Navega a la página de bienvenida
    },
    async getCourse() {
      const response = await coursesApi.getById(this.courseId, {
        withUnitsTopics: true,
      });
      this.course = response.data.data;
      this.units = this.course.units;
    },
    async showTopic(unit, topic) {
      this.currentQuestion = {};
      this.currentAnswer = '';
      this.output = null;
      this.localFeedback = null;
      this.generalFeedback = null;
      this.currentQuestionContent = '';
      this.selectedUnit = unit;
      this.selectedTopic = topic;
      this.topicContent = '';

      const topicContentResponse = (
        await topicsApi.getById(this.selectedTopic.id,
                                {withContent: true, withFiles: true},
        )).data.data.content;

      this.topicContent = marked.parse(await topicContentResponse);
    },
    async getQuestion() {
      const response = await topicsApi.questions.getForStudent(this.selectedTopic.id, this.selectedStudent);
      this.currentQuestion = response.data.data;
      this.currentAnswer = this.currentQuestion.answer;
      this.currentQuestionContent = marked.parse(this.currentQuestion.question);
      this.output = null;
      this.localFeedback = null;
      this.generalFeedback = null;
      this.finalAnswer = null;
      this.startReadQuestion();
    },
    async downloadGuide(){
      const data = await coursesApi.downloadGuide(this.courseId);

      console.log(data);
    },
    async regenerateFeedback() {
      const questionId = this.currentQuestion.id;
      const answerToQuestionId = this.finalAnswer.id;
      const response = await questionsApi.answer.regenerateFeedback(
        questionId,
        answerToQuestionId,
        this.selectedStudent,
      );
      this.localFeedback = response.data.feedback.localFeedback;
      this.generalFeedback = response.data.feedback.generalFeedback;
    },
    async isCorrect() {
      if (!this.timings.startSendResponse) {
        this.timings.startSendResponse = new Date() - 10000;
      }
      questionsApi.answer.recordTiming(this.currentQuestion.id, {
        'user_id': this.selectedStudent,
        'timing': (new Date() - this.timings.startSendResponse) / 1000,
        'timing_type': 'send_response',
      });
      const response = await questionsApi.answer.answer(this.currentQuestion.id, {
        "answer": this.currentAnswer,
        "user_id": this.selectedStudent,
      });
      this.finalAnswer = response.data.answer;
      this.localFeedback = response.data.feedback.localFeedback;
      this.generalFeedback = response.data.feedback.generalFeedback;
      if (this.finalAnswer.isCorrect) {
        this.timings = {
          startReadQuestion: null,
          startSendResponse: null,
        };
      }
    },
  },
};
</script>
