<template>
  <student-layout>
    <div class="">
      <!-- Main content -->
      <main class="container mx-auto mt-8 p-4">
        <!-- Seccion del Perfil del estudiante -->
        <section class="mb-8 rounded-lg bg-white p-6 shadow">
          <div class="mb-6 flex items-center">
            <img
              :src="user.avatar"
              :alt="user.name"
              class="mr-6 size-24 rounded-full"
            >
            <div>
              <h1 class="text-3xl font-bold text-purple-800">
                {{ user.name }}
              </h1>
              <p class="text-gray-600">
                {{ user.email }}
              </p>
              <p class="text-gray-600">
                Miembro desde: {{ user.memberSince }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div class="rounded-lg bg-purple-100 p-4">
              <h3 class="font-semibold text-purple-800">
                Porcentaje de acierto
              </h3>
              <p class="text-2xl font-bold">
                {{ user.successPercentage }}%
              </p>
            </div>
            <div class="rounded-lg bg-purple-100 p-4">
              <h3 class="font-semibold text-purple-800">
                Puntos Totales
              </h3>
              <p class="text-2xl font-bold">
                {{ user.totalPoints }}
              </p>
            </div>
            <div class="rounded-lg bg-purple-100 p-4">
              <h3 class="font-semibold text-purple-800">
                Nivel Actual de Dominio
              </h3>
              <p class="text-2xl font-bold">
                {{ user.currentLevel }}
              </p>
            </div>
          </div>
        </section>

        <!-- Seccion del Desempeño -->
        <h2 class="mb-6 text-3xl font-bold text-purple-800">
          Tu Desempeño en el Curso
        </h2>

        <div class="grid grid-cols-1 gap-6 md:grid-cols-4">
          <!-- Feedback general -->
          <div class="rounded-lg bg-white p-6 shadow md:col-span-4">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Feedback General
            </h3>
            <p>{{ generalFeedback }}</p>
          </div>

          <!-- Temas a reforzar -->
          <div class="rounded-lg bg-white p-6 shadow md:col-span-1">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Temas a Reforzar
            </h3>
            <ul class="list-disc pl-5">
              <li
                v-for="topic in topicsToReinforce"
                :key="topic"
              >
                {{ topic }}
              </li>
            </ul>
          </div>

          <!-- Gráfico de desempeño personal vs curso -->
          <div class="rounded-lg bg-white p-6 shadow md:col-span-2">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Desempeño en el tiempo
            </h3>
            <LineChart
              ref="line_chart"
              :chart-data="lineChartData"
              :chart-options="lineChartOptions"
              class="m-8"
            />
          </div>

          <!-- Gráfico de desempeño por unidad -->
          <div class="col-span-1 rounded-lg bg-white p-6 shadow">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Desempeño por
              <select
                id="Units_Topics"
                ref="radarSelect"
                class="h-[38px]  rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                @change="RadarHandler()"
              >
                <option>
                  Unidades
                </option>
                <option>
                  Temas
                </option>
              </select>
            </h3>
            <RadarChart
              id="Radar_Chart"
              ref="radar_chart"
              :chart-data="radarChartData"
              :chart-options="radarChartOptions"
              class=""
            />
          </div>

          <!-- Gráfico de preguntas (aciertos/malas) en el día -->
          <div class="col-span-2 rounded-lg bg-white p-6 shadow">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Tu actividad diaria
            </h3>
            <BarChart
              ref="bar_chart"
              :chart-data="barChartData"
              :chart-options="barChartOptions"
              class=""
            />
          </div>





          <!-- Historial de evaluaciones -->
          <div class="col-span-2 rounded-lg bg-white p-6 shadow">
            <h3 class="mb-4 text-xl font-semibold text-purple-800">
              Historial de Evaluaciones
            </h3>
            <table class="w-full">
              <thead>
                <tr class="bg-purple-100">
                  <th class="p-2 text-left">
                    Evaluación
                  </th>
                  <th class="p-2 text-left">
                    Nota
                  </th>
                  <th class="p-2 text-left">
                    Fecha
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="evaluation in evaluationHistory"
                  :key="evaluation.id"
                  class="border-b"
                >
                  <td class="p-2">
                    {{ evaluation.name }}
                  </td>
                  <td class="p-2">
                    {{ evaluation.score }}
                  </td>
                  <td class="p-2">
                    {{ evaluation.date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  </student-layout>
</template>

<script>

import LineChart from '@/components/charts/LineChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import RadarChart from '@/components/charts/RadarChart.vue';
import { ref } from 'vue';


import usersApi from '@/api/users';
import { useUserStore } from '@/stores/userStore';
import { useCourseStore } from '@/stores/courseStore';
import unitsApi from '@/api/units';
import topicsApi from '@/api/topics';


export default {
  components: {
    LineChart,
    BarChart,
    RadarChart,
  },
  data() {
    return {
      // Datos para el gradiente de color (gráfico de desempeño)
      width: null,
      height: null,
      gradient: null,

      // Datos para el perfil del usuario
      user: {
        name: "",
        email: "juan.perez@example.com",
        memberSince: "15 de Marzo, 2022",
        avatar: "/src/assets/ranners_pelao.png",
        successPercentage: 78,
        totalPoints: 1250,
        currentLevel: "Intermedio",
        userId: null,
      },

      courseId: null,

      // Datos falsos para el desempeño del curso (igual que antes)
      generalFeedback: "Tu desempeño general es bueno. Has mostrado mejoras consistentes a lo largo del curso, pero aún hay áreas que puedes reforzar para mejorar tu comprensión general.",

      topicsToReinforce: [
        "Estructuras de datos avanzadas",
        "Algoritmos de ordenamiento",
        "Programación orientada a objetos",
      ],

      personalPerformance: 75,
      courseAveragePerformance: 70,

      unitPerformance: [80, 65, 90, 70, 85],

      evaluationHistory : [
        { id: 1, name: "Examen Parcial 1", score: "85/100", date: "2023-03-15" },
        { id: 2, name: "Proyecto Grupal", score: "92/100", date: "2023-04-02" },
        { id: 3, name: "Quiz Unidad 3", score: "18/20", date: "2023-04-20" },
        { id: 4, name: "Examen Final", score: "88/100", date: "2023-05-10" },
      ],

      // Datos de graficos //


      // Datos de gráfico de linea
      lineChartData: {
        labels: [''],
        datasets: [ {
                      label: 'Tú',
                      data: [null],
                      backgroundColor: 'hsl(100, 82%, 57%, 50%)',
                      borderColor: this.gradientColor,
                    },
                    {
                      label: 'El Resto',
                      data: [null],
                      backgroundColor: 'hsl(0,82%, 57%, 25%)',
                      borderColor: 'hsl(0, 82%, 57%, 25%)',
                    },
        ],
      },
      // Opciones de gráfico de linea
      lineChartOptions: {
        responsive: true,
        scales: {
          y: {
            min: 0,
            max: 1,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x',
        },
      },

      // Datos de gráfico de radar
      radarChartDataUnits: null,
      radarChartDataTopics: null,
      radarChartData:{
        labels: [''],
        datasets: [ {
                      label: 'Tú',
                      data: [null],
                      backgroundColor: 'hsl(100, 82%, 57%, 50%)',
                    },
                    {
                      label: 'El Resto',
                      data: [null],
                      backgroundColor:'hsl(0, 82%, 57%, 25%)',
                    },
        ],
      },

      // Opciones de gráfico de radar
      radarChartOptions: {
        responsive: true,
        scales: {
          r:{
            min: 0,
            max: 1,
          },
        },
      },

      correctAnswers: null,
      wrongAnswers: null,


      // Datos de gráfico de barras
      barChartData: {
        labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        datasets: [
          {
            label: 'Fallos', //Fallos debe ir primero para aparecer abajo
            data: [null],
            backgroundColor: 'hsl(0, 82%, 57%, 50%)',
          },
          {
            label: 'Aciertos',
            data: [null],
            backgroundColor: 'hsl(100, 82%, 57%, 50%)',
          },
        ],
      },

      // Opciones de gráfico de barras
      barChartOptions: {
        responsive: true,
        plugins:{
          legend: {
            display: false,
          },
          tooltip:{
            callbacks: {
              afterTitle: function (tooltipItems) {
                let sum = 0;

                tooltipItems.forEach(function(tooltipItem) {
                  sum += tooltipItem.parsed.y;
                });
                return 'Ejercicios: ' + sum;
              },
            },
          },
        },
        scales:{
          y:{
            stacked: true, // Barras se estaquean verticalmente
          },
          x:{
            stacked: true, // Barras se estaquean horizonatlmente (en el mismo espacio)
          },
        },
        interaction:{
          intersect: true,
          mode: 'x',
        },
      },


    };
  },

  async beforeMount() {
    this.getUser();
    this.getCourse();

    this.getLineData();
    this.getRadarData();
    this.getBarData();
  },

  methods: {
    getUser(){
      const userStore = useUserStore();
      this.user.userId = userStore.user.id;
      this.user.name = userStore.user.name;

      // TODO : Construir restos de datos del usuario
    },

    getCourse(){
      const courseStore = useCourseStore();
      this.courseId = courseStore.course.id; /// Esto se va a cambiar después al implementar algún selector de curso
    },

    async getLineData(){
      let response = (await usersApi.stats.getHistory(this.user.userId, this.courseId)).data;

      let dates = [];
      let userHistoryData = [];
      let courseHistoryData = [];
      let index;

      // Se cuenta los datos del curso, ya que por definicion deberian estar todos
      for (index in response.courseStats) {
        dates.push(response.courseStats[index].date);
        courseHistoryData.push(response.courseStats[index].stats);
      }

      // Se sigue con el estudiante, que puede tener datos omitidos si empezó despues el curso
      for (let index = 0; index < dates.length - response.userStats.length; index++) {
        userHistoryData.push(null);
      }
      for (index in response.userStats) {
        userHistoryData.push(response.userStats[index].stats);
      }

      this.lineChartData = {
        labels: dates,
        datasets: [ {
                      label: 'Tú',
                      data: userHistoryData,
                      backgroundColor: 'hsl(100, 82%, 57%, 50%)',
                      borderColor: this.gradientColor,
                    },
                    {
                      label: 'El Resto',
                      data: courseHistoryData,
                      backgroundColor: 'hsl(0,82%, 57%, 25%)',
                      borderColor: 'hsl(0, 82%, 57%, 25%)',
                    },
        ],
      };

      let chart = this.$refs.line_chart.$refs.chartRef.chart;
      chart.update();
    },

    async getRadarData(){
      let responseUnits = (await usersApi.stats.getUnits(this.user.userId, this.courseId)).data;

      let unitLabels = [];
      let unitCourseData = [];
      let unitUserData = [];

      //      al parecer esto no existe aun ¯\_(ツ)_/¯
      //console.log(((await unitsApi.getAll()).data));

      let id;
      let label;
      for (id in responseUnits.courseStats) {
        label = (await unitsApi.getById(parseInt(id)+1)).data.data.name; // +1 para que no falle (a la api aun le faltan datos por devolver)
        unitLabels.push(label);
        unitCourseData.push(responseUnits.courseStats[id]);
        unitUserData.push(responseUnits.userStats[id]);
      }

      if(unitLabels.length < 3) // No quieren ver como aparece un grafico de radar con 2 datos o menos
        for (let index = 0; unitLabels.length < 3; index++) {
          unitLabels.push('');
          unitCourseData.push(null);
          unitUserData.push(null);
        }

      this.radarChartDataUnits = {
        labels: unitLabels,
        datasets: [ {
                      label: 'Tú',
                      data: unitUserData,
                      backgroundColor: 'hsl(100, 82%, 57%, 50%)',
                    },
                    {
                      label: 'El Resto',
                      data: unitCourseData,
                      backgroundColor:'hsl(0, 82%, 57%, 25%)',
                    },
        ],
      };

      let responseTopics = (await usersApi.stats.getTopics(this.user.userId, this.courseId)).data;

      let topicsLabels = [];
      let topicsCourseData = [];
      let topicsUserData = [];

      for (id in responseTopics.courseStats) {
        topicsLabels.push(((await topicsApi.getById(parseInt(id)+1)).data.data.name)); // +1 para que no falle
        topicsCourseData.push(responseTopics.courseStats[id]);
        topicsUserData.push(responseTopics.userStats[id]);
      }


      if(topicsLabels.length < 3) // No quieren ver como aparece un grafico de radar con 2 datos o menos
        for (let index = 0; topicsLabels.length < 3; index++) {
          topicsLabels.push('');
          topicsCourseData.push(null);
          topicsUserData.push(null);
        }

      this.radarChartDataTopics = {
        labels: topicsLabels,
        datasets: [ {
                      label: 'Tú',
                      data: topicsUserData,
                      backgroundColor: 'hsl(100, 82%, 57%, 50%)',
                    },
                    {
                      label: 'El Resto',
                      data: topicsCourseData,
                      backgroundColor:'hsl(0, 82%, 57%, 25%)',
                    },
        ],
      };

      this.radarChartData = this.radarChartDataUnits;
      let chart = this.$refs.radar_chart.$refs.chartRef.chart;
      chart.update();
    },

    async getBarData(){
      const response = (await usersApi.stats.getActivity(this.user.userId, this.courseId)).data;

      let dates = [];
      let correctAnswers = [];
      let wrongAnswers = [];

      let i;
      for(i in response.correctAnswers){
        dates.push(response.correctAnswers[i].date);
        correctAnswers.push(response.correctAnswers[i].count);
      }
      for (i in response.incorrectAnswers) {
        dates.push(response.incorrectAnswers[i].date);
        wrongAnswers.push(response.incorrectAnswers[i].count);
      }

      // Se construyen los dataset a utilizar ///

      //Pasamos las fechas al indice del dia (0 = domingo)
      let dates_day_index = [];
      for (let index = 0; index < dates.length; index++) {
        // Se necesitan las fechas en formato ISO (YYYY-MM-DD) , pero se obtienen en formato (DD-MM-YYYY)
        dates[index] = dates[index].split('-').reverse().join('-');

        let aux_date = new Date(dates[index]);
        dates_day_index.push(aux_date.getDay());
      }

      // Ocupamos los ultimos 7 datos y cortamos hasta el lunes
      let aux_correctAnswers = [];
      let aux_wrongAnswers = [];
      var day;
      for (let index = dates_day_index.length-1; index >= 0; index--) {
        day = dates_day_index[index];
        if (day == 0 && index != dates_day_index.length-1){
          break;
        }
        aux_correctAnswers.push(correctAnswers[index]);
        aux_wrongAnswers.push(wrongAnswers[index]);
      }
      //Chequeamos que hayamos alcanzado el domingo, sino rellenamos con 0s
      for (let index = day-1; index > 0; index--) {
        aux_correctAnswers.push(0);
        aux_wrongAnswers.push(0);
      }

      this.correctAnswers = aux_correctAnswers.reverse();
      this.wrongAnswers = aux_wrongAnswers.reverse();

      this.barChartData = {
        labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        datasets: [
          {
            label: 'Fallos', //Fallos debe ir primero para aparecer abajo
            data: this.wrongAnswers,
            backgroundColor: 'hsl(0, 82%, 57%, 50%)',
          },
          {
            label: 'Aciertos',
            data: this.correctAnswers,
            backgroundColor: 'hsl(100, 82%, 57%, 50%)',
          },
        ],
      };

      let chart = this.$refs.bar_chart.$refs.chartRef.chart;
      chart.update();
    },

    // Función para generar gradiente de color
    getGradient(ctx, chartArea) {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!this.gradient || this.width !== chartWidth || this.height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        this.width = chartWidth;
        this.height = chartHeight;
        this.gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        this.gradient.addColorStop(0, 'hsl(25, 82%, 57%)'); // Gradiente de naranjo a verde
        this.gradient.addColorStop(1, 'hsl(100, 82%, 57%)');
        return this.gradient;
      }
    },
    gradientColor(context){
      const chart = context.chart;
      const {ctx, chartArea} = chart;

      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      return this.getGradient(ctx, chartArea);
    },

    RadarHandler(){
      let chart = this.$refs.radar_chart.$refs.chartRef.chart;

      const selectedText = this.$refs.radarSelect.value;

      if(selectedText == "Unidades"){
        chart.data = this.radarChartDataUnits;
      } else {
        chart.data = this.radarChartDataTopics;
      }

      chart.update();
    },




  },
};

</script>
