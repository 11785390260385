<template>
  <student-layout>
    <div class="py-12">
      <div class="container mx-auto px-4">
        <div class="mx-auto max-w-3xl rounded-lg bg-white p-8 shadow-lg">
          <!-- Progress Bar -->
          <div class="mb-8 flex justify-center space-x-2">
            <button
              v-if="progressBarAuxNumber > 10"
              @click="progressBarLeft()"
            >
              <Icon
                icon="mingcute:left-line"
                class="text-2xl text-purple-300"
              />
            </button>
            <!--Truquito para mantener la relación del espacio como si hubiera botón-->
            <Icon
              v-else
              icon="mingcute:left-line"
              class="text-2xl text-white"
            />
            <!---->
            <template
              v-for="i in totalQuestions"
              :key="i"
            >
              <!-- Magia aritmética que me fue revelada en un sueño-->
              <button
                v-if="(progressBarAuxNumber-1-((progressBarAuxNumber-1)%10))/10 === (i-1-((i- 1)%10))/10"
                :class="[
                  'flex size-8 items-center justify-center rounded-full text-sm font-bold transition-colors duration-200',
                  selectedResponses[i - 1] !== null ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-600',
                  i === questionNumber ? 'ring-2 ring-purple-300' : '',
                  'cursor-pointer hover:bg-purple-400'
                ]"
                @click="goToQuestion(i)"
              >
                {{ i }}
              </button>
            </template>
            <button
              v-if="totalQuestions - progressBarAuxNumber > 9 && totalQuestions > 10"
              @click="progressBarRight()"
            >
              <Icon
                icon="mingcute:right-line"
                class="text-2xl text-purple-300"
              />
            </button>
            <!--Truquito para mantener la relación del espacio como si hubiera botón-->
            <Icon
              v-else
              icon="mingcute:right-line"
              class="text-2xl text-white"
            />
            <!---->
          </div>

          <!-- Question Header -->
          <div class="mb-2 flex items-center justify-between">
            <h2 class="text-lg font-semibold text-purple-800">
              Pregunta {{ questionNumber }}:
            </h2>
            <span class="rounded-full bg-purple-100 px-2 py-1 text-xs font-medium text-purple-800">
              {{ activeQuestion.difficultyText === 'easy' ? 'Fácil' :
                activeQuestion.difficultyText === 'medium' ? 'Medio' : 'Difícil' }}
            </span>
          </div>

          <!-- Question Display -->
          <div class="m-auto text-end">
            <span class="m-auto text-gray-400 ">
              {{ activeQuestion.topicName }}
            </span>
          </div>
          <div>
            <p class="p-6 text-gray-900">
              {{ activeQuestion.question }}
            </p>
          </div>

          <!-- Answer Options -->
          <div class="mb-8 space-y-4">
            <!----   Preguntas de Alternativas ------>
            <template
              v-for="(alternative, index) in activeQuestion.multipleChoice.alternatives"
              v-if="activeQuestion.type == 'multiple_choice'"
              :key="index"
            >
              <button
                :class="[
                  'w-full rounded-lg border-2 p-4 text-left text-gray-800 transition-all duration-300 ease-in-out',
                  selectedResponses[questionNumber - 1] === index
                    ? 'scale-105 border-purple-500 bg-purple-100 shadow-md'
                    : 'border-purple-300 bg-white hover:bg-purple-50',
                  'focus:outline-none focus:ring-2 focus:ring-purple-500'
                ]"
                @click="selectAlternative(index)"
              >
                Alternativa {{ ['A', 'B', 'C', 'D'][index] }} : {{ alternative }}
              </button>
            </template>

            <!----   Preguntas de Verdadero o Falso ------>
            <template
              v-if="activeQuestion.type == 'true_false'"
            >
              <div class="grid grid-rows-1 gap-8 text-center">
                <div class="w-full">
                  <button
                    :class="[
                      'w-1/4 rounded-lg border-2 p-4 text-center text-gray-800 transition-all duration-300 ease-in-out',
                      selectedResponses[questionNumber - 1] === true
                        ? 'scale-105 border-purple-500 bg-purple-100 shadow-md'
                        : 'border-purple-300 bg-white hover:bg-purple-50',
                      'focus:outline-none focus:ring-2 focus:ring-purple-500'
                    ]"
                    @click="selectAlternative(true)"
                  >
                    Verdadero
                  </button>
                </div>
                <div class="w-full">
                  <button
                    :class="[
                      'w-1/4 rounded-lg border-2 p-4 text-center  text-gray-800 transition-all duration-300 ease-in-out',
                      selectedResponses[questionNumber - 1] === false
                        ? 'scale-105 border-purple-500 bg-purple-100 shadow-md'
                        : 'border-purple-300 bg-white hover:bg-purple-50',
                      'focus:outline-none focus:ring-2 focus:ring-purple-500'
                    ]"
                    @click="selectAlternative(false)"
                  >
                    Falso
                  </button>
                </div>
              </div>
            </template>

            <!----   Preguntas de Respuesta Abierta ------>
            <template
              v-if="activeQuestion.type == 'open'"
            >
              <textarea
                v-model="selectedResponses[questionNumber - 1]"
                class="w-full rounded border border-purple-300 p-1 transition-all duration-300 ease-in-out hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500"
                rows="4"
              />
            </template>
          </div>

          <!-- Navigation Buttons -->
          <div class="flex justify-between">
            <button
              v-if="questionNumber > 1"
              class="px-6 py-2 font-medium text-purple-700"
              @click="PreviousQuestion"
            >
              Anterior
            </button>
            <div
              v-else
              class="w-24"
            />

            <button
              v-if="questionNumber < totalQuestions"
              class="rounded-lg bg-purple-600 px-6 py-2 font-medium text-white transition-colors duration-200 hover:bg-purple-700"
              @click="NextQuestion"
            >
              Siguiente
            </button>
            <button
              v-else
              class="rounded-lg bg-green-600 px-6 py-2 font-medium text-white hover:bg-green-600"
              @click="showConfirmModal"
            >
              Terminar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div
      v-if="isConfirmModalVisible"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="w-full max-w-md rounded-lg bg-white p-8">
        <h2 class="mb-4 text-2xl font-bold text-purple-800">
          Confirmar finalización
        </h2>
        <p class="mb-6 text-gray-700">
          ¿Estás seguro de que deseas terminar el cuestionario? Una vez finalizado, no podrás volver a editar tus respuestas.
        </p>
        <div class="flex justify-end space-x-4">
          <button
            class="px-4 py-2 text-gray-800"
            @click="isConfirmModalVisible = false"
          >
            Cancelar
          </button>
          <button
            class="rounded bg-green-600 px-4 py-2 text-white hover:bg-green-600"
            @click="EndQuestionnaire"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </student-layout>
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import { marked } from 'marked';
import questionsApi from '@/api/questions';
// import questionnariesApi from '@/api/questionnaires';

import Sidebar from '@/components/sidebar.vue';
import Title from '@/components/title.vue';
import Button from '@/components/button.vue';
import LabelComponent from '@/components/label.vue';
import QuestionnaireQuestion from '@/components/questionnaireQuestion.vue';
import questions from '@/api/questions';

export default {
  components: {
    Sidebar,
    Title,
    Button,
    LabelComponent,
    QuestionnaireQuestion,
  },
  data() {
    return {
      course: {},
      units: [],
      expandedUnit: null,
      selectedUnit: null,
      selectedTopic: null,
      selectedStudent: 11,
      currentQuestion: {},
      currentAnswer: '',
      finalAnswer: null,
      localFeedback: null,
      generalFeedback: null,
      output: null,
      topicContent: '',
      currentQuestionContent: '',
      timings: {
        startReadQuestion: null,
        startSendResponse: null,
      },
      courseId: this.$route.params.courseId,

      progressWidth: 'width: 0%',
      // Preguntas estaticas de prueba----------
      questionList: {
        0: {
          'topicName': 'Educación Física',
          'question': 'En esta pregunta se hacen muchas preguntas preguntosas, como por ejemplo cuando uno habla de matematicas y siempre menciona a ciertos creadores de teoremas, como pitagoras o a veces euclides, no como en filosofia que estan los filosofos como Platon o Aristoteles, que pueden filosofar pero no calcular como los otros, entonces la Alternativa B es la correcta',
          'difficultyText': 'medium',
          'multipleChoice': {
            'alternatives': ['function', 'func', '-definir'],
            'correctAlternative': '0',
            'questionId': '420',
          },
          'type': 'multiple_choice',
        },
        1: {
          'topicName': 'Matemáticas',
          'question': '3+5?????',
          'difficultyText': 'hard',
          'multipleChoice': {
            'alternatives': ['7', '4', '3', '9'],
            'correctAlternative': '3',
            'questionId': '69',
          },
          'type': 'multiple_choice',
        },
        2: {
          'topicName': 'Computación Cuántica',
          'question': 'Cuál de los siguientes se ocupa dentro del algoritmo de Shor: ',
          'difficultyText': 'easy',
          'multipleChoice': {
            'alternatives': ['Gauss', 'Fourier', 'Euler', 'Einstein'],
            'correctAlternative': '2',
            'questionId': '80085',
          },
          'type': 'multiple_choice',
        },
        3: {
          'topicName': 'Física Moderna',
          'question': 'Cuando la temperatura de un cuerpo negro aumenta, la longitud de onda de su radiación aumenta. ',
          'difficultyText': 'medium',
          'trueOrFalse': {
            'correctAlternative': false,
            'questionId': '1234',
          },
          'type': 'true_false',
        },
        4: {
          'topicName': 'Moral y Ética',
          'question': 'Le pagan su sueldo del mes en su empresa, pero se encuentra con un gran dilema. La compañia ha estado usando modelos de IA entrenados con datos extraidos sin permiso de los autores. Usted debe decidir si gastar su dinero en skins de LoL o comprando cartas Yu-Gi-Oh. Justifique su respuesta: ',
          'difficultyText': 'hard',
          'open': {
            'correctResponse': 'La respuesta es un dilema, ya que gastar en skins de lol es caro y no sirve, mientras que gastar en yugioh es caro, pero puede ser valioso si se encuentra en este mundo. La respuesta correcta debería ser yugioh',
            'questionId': '4321',
          },
          'type': 'open',
        },
      },
      activeQuestion: {
        'topicName': 'Educación Física',
        'question': 'En esta pregunta se hacen muchas preguntas preguntosas, como por ejemplo cuando uno habla de matematicas y siempre menciona a ciertos creadores de teoremas, como pitagoras o a veces euclides, no como en filosofia que estan los filosofos como Platon o Aristoteles, que pueden filosofar pero no calcular como los otros, entonces la Alternativa B es la correcta',
        'difficultyText': 'medium',
        'multipleChoice': {
          'alternatives': ['function', 'func', '-definir'],
          'correctAlternative': '0',
          'questionId': '420',
        },
        'type': 'multiple_choice',
      },
      // ---------------------
      questionNumber: 1,
      maxQuestionCompleted: 0,
      totalQuestions: 5,
      selectedResponses: [],
      isConfirmModalVisible: false,
      progressBarAuxNumber: 1,
    };
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
      this.getQuestionnaire();
    },
  },
  async mounted() {
    this.getCourse();
    this.getQuestionnaire();
    this.selectedResponses = new Array(this.totalQuestions).fill(null);
  },
  methods: {
    async getQuestionnaire(){},
    async getCourse() {
      const response = await coursesApi.getById(this.courseId, {
        withUnitsTopics: true,
      });
      this.course = response.data.data;
      this.units = this.course.units;

      document.title = 'Curso ' + this.course.name;
    },
    CalculateProgressWidth(){
      let percentage = (this.maxQuestionCompleted/this.totalQuestions)*100;
      this.progressWidth = "width: "+percentage+"%";
    },
    NextQuestion() {
      this.activeQuestion = this.questionList[this.questionNumber];
      this.questionNumber++;
      this.progressBarAuxNumber = this.questionNumber;
      this.maxQuestionCompleted = Math.max(this.questionNumber-1, this.maxQuestionCompleted);
    },
    PreviousQuestion() {
      this.activeQuestion = this.questionList[this.questionNumber-2];
      this.questionNumber--;
    },
    progressBarRight(){
      this.progressBarAuxNumber = this.progressBarAuxNumber+10;
      console.log(this.progressBarAuxNumber);
    },
    progressBarLeft(){
      this.progressBarAuxNumber = this.progressBarAuxNumber-10;
      console.log(this.progressBarAuxNumber);
    },
    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },
    EndQuestionnaire(){
      this.progressWidth = "width: 100%";
      console.log("Respuestas seleccionadas:", this.selectedResponses);
      this.$router.push({ name: 'student.exercise.stats', params: { questionnaireId: 0 } });
    },
    goToQuestion(questionIndex) {
      this.questionNumber = questionIndex;
      this.activeQuestion = this.questionList[questionIndex - 1];
      this.maxQuestionCompleted = Math.max(this.maxQuestionCompleted, questionIndex);
    },
    selectAlternative(index) {
      this.selectedResponses[this.questionNumber - 1] = index;
    },
  },
};
</script>
