<template>
  <student-layout>
    <main class="">
      <div class="container mx-auto px-4 py-8">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
      </div>
      <div class="container mx-auto flex flex-col gap-6 px-4">
        <div class="container mx-auto flex flex-col">
          <div>
            <Title :text="`Archivos del curso ${course.name}`" />
          </div>
        </div>
        <div class="sticky top-0 z-10 rounded bg-white py-4 shadow-md">
          <div class="ml-4 flex flex-col gap-8 md:flex-row">
            <div class="w-full">
              <div class="relative flex w-full space-x-2 ">
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Units"
                    text="Unidades"
                  />
                  <select
                    id="Units"
                    v-model="filterFiles.unitId"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option
                      v-for="unit in units"
                      :key="unit.id"
                      :value="unit.id"
                    >
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
                <div class="flex w-1/4 flex-col space-y-2">
                  <LabelComponent
                    for="Topics"
                    text="Temas"
                  />
                  <select
                    id="Topics"
                    v-model="filterFiles.topicId"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option
                      v-for="topic in topics"
                      :key="topic.id"
                      :value="topic.id"
                    >
                      {{ topic.name }}
                    </option>
                  </select>
                </div>
                <div class="mt-9">
                  <a
                    class="cursor-pointer font-bold text-purple-700"
                    @click="cleanFilteredFiles()"
                  >
                    Limpiar filtros
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-4 rounded-md border bg-white p-6">
          <div class="grid grid-cols-12">
            <div class="col-span-1" />
            <div class="col-span-4 truncate font-bold">
              Nombre
            </div>
            <div class="col-span-5 font-bold">
              Temas
            </div>
            <div class="col-span-1" />
          </div>
          <div class="flex flex-col">
            <div
              v-for="file in filteredFiles"
              :key="file.id"
              class="grid grid-cols-12 border-y py-2"
            >
              <div class="col-span-1">
                <input
                  type="checkbox"
                  :checked="selectedFiles.includes(file.id)"
                  @input="changeSelectedFiles(file)"
                >
              </div>
              <div class="col-span-4 overflow-hidden truncate">
                <span class="sm:text-sm md:text-base">
                  {{ file.originalName }}
                </span>
              </div>
              <div class="col-span-5">
                <div
                  v-for="topic in file.topics"
                  :key="topic.id"
                  class="sm:text-sm md:text-base"
                >
                  {{ topic.name }}
                </div>
              </div>
              <div class="col-span-1">
                <div
                  class="col-span-1 cursor-pointer text-purple-600 underline"
                  @click="createChat(file)"
                >
                  Consultar
                </div>
              </div>
              <div
                :href="file.url"
                class="col-span-1 cursor-pointer"
                @click="downloadFile(file)"
              >
                <Icon
                  icon="mdi:download"
                  class="mt-1 text-purple-700"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sticky para el boton de descarga grupal -->
      <div
        v-if="multipleFilesSelected"
        class="fixed inset-x-0 bottom-0 p-4 shadow-md"
      >
        <div class="container mx-auto flex max-w-xs justify-end">
          <ButtonComponent
            @click="downloadFiles"
          >
            Descargar Archivos Seleccionados
          </ButtonComponent>
        </div>
      </div>
    </main>
  </student-layout>
</template>


<script>
import { reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';
import coursesApi from '@/api/courses';
import filesApi from '@/api/files';
import Title from '@/components/title.vue';
import ButtonComponent from '@/components/button.vue';
import LabelComponent from '@/components/label.vue';
import { RouterLink } from 'vue-router';
import chatsApi from '@/api/chats';

export default {
  components: {
    Icon,
    Title,
    ButtonComponent,
    LabelComponent,
    RouterLink,
  },
  data() {
    return {
      files: [],
      selectedFiles: [],
      course: {},
      topics: [],
      units: [],
      filterFiles: {
        unitId: null,
        topicId: null,
      },
      filteredFiles: [],
      courseId: this.$route.params.courseId,
    };
  },
  computed: {
    multipleFilesSelected() {
      return this.selectedFiles.length > 1;
    },
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getFiles();
    },
    filterFiles:{
      handler(){
        this.filteredFiles = this.files.filter((file) => {
          let topics = file.topics.map(topic => topic.id);
          let units = file.topics.map(topic => topic.unitId);
          let isValid = true;
          if (this.filterFiles.unitId) {
            isValid = isValid && units.includes(this.filterFiles.unitId);
          }
          if (this.filterFiles.topicId) {
            isValid = isValid && topics.includes(this.filterFiles.topicId);
          }
          return isValid;
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.getFiles();
    this.course = (await coursesApi.getById(this.courseId)).data.data;
    this.topics = (await coursesApi.topics.getAll(this.courseId)).data.data;
    this.units = (await coursesApi.units.getAll(this.courseId)).data.data;
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Esto navega a la página anterior
    },
    async downloadFile(file) {
      filesApi.download(file.id).then(response => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.filename;
        link.click();
      });
    },
    async downloadFiles() {
      filesApi.downloadMultiple(this.selectedFiles).then(response => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'files.zip';
        link.click();
      });
    },
    async getFiles() {
      this.files = (await coursesApi.files.getAll(this.courseId)).data.data;
      this.filteredFiles = this.files;
    },
    cleanFilteredFiles() {
      this.filterFiles.unitId = null;
      this.filterFiles.topicId = null;
      this.filteredFiles = this.files;
    },
    async createChat(file) {
      const response = (await coursesApi.chats.create(this.courseId, {awsfileId: file.id})).data.data;

      this.$router.push({
        name: 'student.chat.show',
        params: {
          chatId: response.id,
          courseId: this.courseId,
        },
      });
    },
    changeSelectedFiles(file) {
      const index = this.selectedFiles.indexOf(file.id);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      } else {
        this.selectedFiles.push(file.id);
      }
    },
  },
};
</script>
