<template>
  <div >
    <p class="font-bold">
      Pregunta: {{questionNumber}}
    </p>
    <div style="max-width: 1000px;">
      <div class="bg-purple-200 rounded mr-4 ">
        <!-- Sección Superior -->
        <div class="flex items-center grid grid-cols-2 m-2">
          <div class="bg-gray-100 h-1/2 rounded-md ">
            <span class="ml-2">
              Temas:
              {{question.topicName}}
            </span>
          </div>
          <div class="flex justify-end mr-2">
            <div v-if="question.difficultyText=='easy'">
              <Icon
                icon="game-icons:frog-foot"
                class="text-2xl text-lime-600"
              />
              <span>Fácil</span>
            </div>
            <div v-if="question.difficultyText=='medium'">
              <Icon
                icon="game-icons:frog"
                class="text-2xl text-amber-500"
              />
              <span>Medio</span>
            </div>
            <div v-if="question.difficultyText=='hard'">
              <Icon
                icon="game-icons:frog-prince"
                class="text-2xl text-red-600"
              />
              <span>Difícil</span>
            </div>
          </div>
        </div>

        <!-- Sección Principal -->
        <div class="bg-gray-100 m-2 rounded-lg">
          <p class="m-2 text-left">
            {{question.question}}
          </p>
        </div>
      </div>
      <div>
        <div
          v-if="question.type=='multiple_choice'"
          v-for="(alternative, index) in question.multipleChoice.alternatives"
          :key="index"
          name="alternatives"
          aria-checked="false"
          class="bg-purple-200 my-2 mr-4 rounded-lg border-purple-700"
          role="button"
          v-on:click="handleAlternatives(index); $emit('select-alternative', index);"
        >
          <span class="ml-2">
            {{alternative}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import questions from '@/api/questions';

export default {
  components: {
    Icon,
  },
  props: {
    questionNumber:{
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  watch:{
    question: function (val){
      let alternatives = document.getElementsByName('alternatives');
      for (let i = 0; i < alternatives.length; i++) {
        alternatives[i].ariaChecked = false;
        alternatives[i].style.borderWidth = '0px';
      }
    }
  },
  methods: {
    handleAlternatives(index){
      let alternatives = document.getElementsByName('alternatives');
      for (let i = 0; i < alternatives.length; i++) {
        alternatives[i].ariaChecked = false;
        alternatives[i].style.borderWidth = '0px';
      }
      alternatives[index].ariaChecked = true;
      alternatives[index].style.borderWidth = '4px';
    }
  },
};
</script>
