import api from './index';

export default {
  download(id) {
    return api.post(`/api/files/${id}`, { responseType: 'blob' });
  },
  downloadMultiple(ids) {
    return api.post('/api/files/download-multiple', {'awsfiles': ids}, { responseType: 'blob' });
  },
};
