<template>
  <label
    :for="this.for"
    class="mb-block text-sm font-medium text-gray-700"
  >{{ text }}</label>
</template>

<script>
export default {
  name: 'LabelComponent',
  props: {
    for: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
