<template>
  <div :class="`flex flex-col`">
    <navbar-no-course v-if="isTeacherCoursesRoute" />
    <navbar v-else />
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Navbar from '@/layouts/teacher/navbar.vue';
import NavbarNoCourse from '@/layouts/teacher/navbar_no_course.vue';

const route = useRoute();

const isTeacherCoursesRoute = computed(() => {
  return route.path.startsWith('/teacher/courses');
});

defineProps({
  clases: {
    type: String,
    default: '',
  },
});
</script>
