import api from './index';

export default {
  getById(id, params) {
    return api.get(`/api/chats/${id}`, { params });
  },
  delete(id) {
    return api.delete(`/api/chats/${id}`);
  },
  messages: {
    create(id, data) {
      return api.post(`/api/chats/${id}/messages`, data);
    },
  },
};
