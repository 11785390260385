<template>
  <teacher-layout>
    <div class="mx-auto my-10 max-w-lg rounded-lg bg-white p-8 shadow">
      <form @submit.prevent="updateCourse">
        <label for="name">Course Name</label>
        <input
          id="name"
          v-model="course.name"
          type="text"
        >

        <label for="description">Course Description</label>
        <textarea
          id="description"
          v-model="course.description"
        />

        <button type="submit">
          Update Course
        </button>
      </form>
    </div>
  </teacher-layout>
</template>

<script>
import coursesApi from '@/api/courses';

export default {
  data() {
    return {
      course: {
        id: null,
        name: '',
        description: '',
      },
    };
  },
  async mounted() {
    const { data: {data: course} } = await coursesApi.getById(this.$route.params.courseId);
    this.course = course;
  },
  methods: {
    async updateCourse() {
      await coursesApi.update(this.course.id, this.course);

      this.$router.push({ name: 'teacher.course.show', params: { id: this.course.courseId } });
    },
  },
};
</script>
