<template>
  <student-layout>
    <main class="mt-12 p-8 pb-24">
      <!-- Aumentado el padding inferior para dar espacio al botón fijo -->
      <div class="mx-auto max-w-4xl">
        <div
          class="mb-8 -translate-y-10 overflow-hidden rounded-lg bg-white opacity-0 shadow-lg transition duration-700 ease-in-out"
          :class="{ 'translate-y-0 opacity-100 delay-200': isVisible }"
        >
          <div class="relative h-64 bg-purple-700">
            <div class="absolute inset-0 flex items-center justify-center">
              <h1 class="text-4xl font-bold text-white">
                Bienvenido a {{ course.name }}
              </h1>
            </div>
          </div>
          <div class="p-6">
            <p class="mb-4 text-lg text-gray-700">
              {{ course.description }}
            </p>
            <div class="mb-6 grid grid-cols-2 gap-4">
              <div>
                <h2 class="text-xl font-semibold text-gray-800">
                  Profesor
                </h2>
                <p class="text-gray-600">
                  {{ course.professor }}
                </p>
              </div>
              <div>
                <h2 class="text-xl font-semibold text-gray-800">
                  Horario
                </h2>
                <p class="text-gray-600">
                  {{ course.schedule }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div
            class="-translate-y-10 rounded-lg bg-white p-6 opacity-0 shadow-md transition duration-700 ease-in-out"
            :class="{ 'translate-y-0 opacity-100 delay-300': isVisible }"
          >
            <h2 class="mb-4 text-2xl font-semibold text-gray-800">
              Objetivos del Curso
            </h2>
            <ul class="list-inside list-disc space-y-2 text-gray-600">
              <li>Comprender los conceptos fundamentales de la materia</li>
              <li>Desarrollar habilidades prácticas en el área de estudio</li>
              <li>Aplicar los conocimientos adquiridos en proyectos reales</li>
            </ul>
          </div>
          <div
            class="-translate-y-10 rounded-lg bg-white p-6 opacity-0 shadow-md transition duration-700 ease-in-out"
            :class="{ 'delay-400 translate-y-0 opacity-100': isVisible }"
          >
            <h2 class="mb-4 text-2xl font-semibold text-gray-800">
              Contenido del Curso
            </h2>
            <ul class="list-inside list-decimal space-y-2 text-gray-600">
              <li>Introducción a la materia</li>
              <li>Fundamentos teóricos</li>
              <li>Aplicaciones prácticas</li>
              <li>Estudios de caso</li>
              <li>Proyectos y evaluaciones</li>
            </ul>
          </div>
        </div>
      </div>
    </main>

    <!-- Botón fijo en la parte inferior -->
    <div
      class="fixed inset-x-0 bottom-0"
      :class="{ 'translate-y-0 opacity-100 delay-500': isVisible }"
    >
      <div class="mx-auto max-w-4xl">
        <Button
          class="w-full bg-purple-600 px-6 py-3 text-lg font-semibold text-white hover:bg-purple-700"
          @click="startCourse"
        >
          Comenzar a estudiar :D
        </Button>
      </div>
    </div>
  </student-layout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useCourseStore } from '@/stores/courseStore';
import Button from '@/components/button.vue';

const router = useRouter();
const courseStore = useCourseStore();
const course = ref({});
const isVisible = ref(false);

onMounted(() => {
  course.value = courseStore.course;
  if (!course.value.id) {
    router.push({ name: 'student.course.index' });
  }

  setTimeout(() => {
    isVisible.value = true;
  }, 200);
});

const startCourse = () => {
  router.push({ name: 'student.course.show', params: { courseId: course.value.id } });
};
</script>
