import api from './index';

export default {
  update(id, data) {
    return api.patch(`/api/questions/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/questions/${id}`);
  },
  generate(params){
    return api.get(`/api/questions/generate`, { params });
  },
  answer: {
    answer(questionId, data) {
      return api.post(`/api/questions/${questionId}/answer`, data);
    },
    recordTiming(questionId, data) {
      return api.post(`/api/questions/${questionId}/record-timing`, data);
    },
    regenerateFeedback(questionId, answerToQuestionId, userId) {
      return api.post(`/api/questions/${questionId}/regenerate-feedback`, {answerToQuestionId, userId});
    },
  },
};
