import api from './index';

export default {
  csrfCookie() {
    return api.get('/sanctum/csrf-cookie');
  },
  getSession(){
    return api.get('/api/user');
  },
  register(data) {
    return api.post('/register', data);
  },
  login(data) {
    return api.post('/login', data);
  },
  logout() {
    return api.post('/logout');
  },
  stats : {
    getTopics(userId, courseId) {
      return api.get(`/api/users/${userId}/stats/${courseId}/topics`);
    },
    getUnits(userId, courseId) {
      return api.get(`/api/users/${userId}/stats/${courseId}/units`);
    },
    getHistory(userId, courseId) {
      return api.get(`/api/users/${userId}/stats/${courseId}/history`);
    },
    getActivity(userId, courseId) {
      return api.get(`/api/users/${userId}/stats/${courseId}/activity`);
    },
  },
};
