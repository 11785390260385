<template>
  <nav class="z-50 w-full bg-purple-700 p-4 shadow-lg">
    <div class="container mx-auto flex items-center justify-between">
      <div class="flex items-center space-x-8">
        <!-- Logo -->
        <router-link
          to="/"
          class="flex items-center space-x-2"
        >
          <!-- Logo -->
          <img
            src="@/assets/ranner500.png"
            class="h-10 w-auto"
          >

          <!-- Text -->
          <div class="flex flex-col items-start">
            <h1 class="text-2xl font-bold text-white">
              RANNER
            </h1>
            <p class="text-base font-medium text-white">
              Profesor
            </p>
          </div>
        </router-link>


        <div class="flex space-x-4">
          <router-link
            v-for="(link, index) in navLinks"
            :key="index"
            :to="link.to"
            :class="[
              'flex items-center rounded-md px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out hover:bg-purple-500',
              currentRoute.includes(link.route)
                ? 'bg-purple-600 shadow-inner'
                : 'hover:bg-gray-700'
            ]"
          >
            <Icon
              :icon="link.icon"
              class="mr-2 size-5"
            />
            {{ link.text }}
          </router-link>
        </div>
        <!-- User Profile Section -->
      </div>
      <router-link
        :to="{ name: 'teacher.profile.show' }"
        class="flex items-center space-x-2 rounded-md px-3 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
      >
        <Icon
          icon="mdi:account-circle"
          class="size-6"
        />
        <span>{{ user.name }}</span>
      </router-link>
    </div>
  </nav>
</template>


<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { useCourseStore } from '@/stores/courseStore';
import coursesApi from '@/api/courses';
import logo from '@/assets/ranner500.png';


const courseStore = useCourseStore();
const openMenu = ref(null);
const menuCurso = ref(null);
const route = useRoute();
const router = useRouter();

// Placeholder user data
const user = ref({
  name: 'Juan Pérez',
});

const navLinks = [
  { to: { name: 'teacher.course.index' }, route: 'teacher.course.index', icon: 'mdi:book-open-variant', text: 'Cursos' },
  { to: { name: 'teacher.course.questions', params: { courseId: courseStore.course?.id } }, route: 'teacher.course.questions', icon: 'mdi:frequently-asked-questions', text: 'Ejercicios' },
  { to: { name: 'teacher.course.files', params: { courseId: courseStore.course?.id } }, route: 'teacher.course.files', icon: 'mdi:file-multiple', text: 'Archivos' },
];

coursesApi.getAll().then((response) => {
  courseStore.setCourses(response.data.data);
});

const toggleMenu = (menu) => {
  openMenu.value = openMenu.value === menu ? null : menu;
};

const handleClickOutside = (event) => {
  if (openMenu.value && !menuCurso.value?.contains(event.target)) {
    openMenu.value = null;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const updateCourse = (course) => {
  router.push({ name: route.name, params: { ...route.params, courseId: course.id } });
  courseStore.setCourse(course);
};

const currentRoute = route.name;
</script>
