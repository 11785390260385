<template>
  <nav class="z-50 w-full bg-purple-700 p-4 shadow-lg">
    <div class="container mx-auto flex items-center justify-between">
      <div class="flex items-center space-x-8">
        <router-link
          to="/"
          class="flex items-center space-x-2"
        >
          <!-- Logo -->
          <img
            src="@/assets/ranner500.png"
            class="h-10 w-auto"
            alt="RANNER logo"
          >

          <!-- Text -->
          <div class="flex flex-col items-start">
            <h1 class="text-2xl font-bold text-white">
              RANNER
            </h1>
            <p class="text-base font-medium text-white">
              Estudiante
            </p>
          </div>
        </router-link>

        <div class="flex items-center space-x-4">
          <!-- Navigation Links -->
          <div class="flex space-x-4">
            <a
              href="#"
              class="flex items-center rounded-md px-3 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
              @click.prevent="goToStudentCourses"
            >
              <Icon
                icon="mdi:format-list-bulleted"
                class="mr-2 size-5"
              />
              Mis cursos
            </a>

            <router-link
              :to="{ name: 'student.course.show', params: { courseId: courseStore.course.id } }"
              class="flex items-center rounded-md px-3 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
            >
              <Icon
                icon="mdi:book-open-variant"
                class="mr-2 size-5"
              />
              Aprender
            </router-link>

            <router-link
              :to="{name: 'student.exercise.index', params: { courseId: courseStore.course.id } }"
              class="flex items-center rounded-md px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
            >
              <Icon
                icon="mdi:dumbbell"
                class="mr-2 size-5"
              />
              Ejercitar
            </router-link>

            <router-link
              to="/student/chat/course/1"
              class="flex items-center rounded-md px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
            >
              <Icon
                icon="mdi:chat"
                class="mr-2 size-5"
              />
              Chatear
            </router-link>
          </div>
        </div>
      </div>

      <!-- User Profile Section -->
      <router-link
        :to="{ name: 'student.profile.show' }"
        class="flex items-center space-x-2 rounded-md px-3 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-purple-600"
      >
        <img
          src="@/assets/ranners_palido.png"
          class="size-8"
        >

        <span>{{ user.name }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { useCourseStore } from '@/stores/courseStore';
import coursesApi from '@/api/courses';

const courseStore = useCourseStore();
const router = useRouter();

// Placeholder user data
const user = ref({
  name: 'Juan Pérez',
});

onMounted(() => {
  coursesApi.getAll().then((response) => {
    courseStore.setCourses(response.data.data);
  });
});

const goToCourse = (course) => {
  courseStore.setCourse(course);
  router.push({ name: 'student.course.show', params: { courseId: course.id } });
};

const goToStudentCourses = () => {
  router.push({ name: 'student.course.index' });
};
</script>

