<template>
  <teacher-layout>
    <main class="">
      <div class="container mx-auto p-10">
        <button
          class="mb-6 flex items-center gap-2 rounded-md bg-purple-700 px-4 py-2 text-white transition-colors hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          @click="goBack"
        >
          <Icon
            icon="mdi:arrow-left"
            class="size-5"
          />
          Volver atrás
        </button>
        <div class="rounded-lg bg-white p-8 shadow-lg">
          <h1 class="mb-6 text-3xl font-bold text-gray-800">
            {{ course?.name }}
          </h1>

          <section class="mb-8">
            <h2 class="mb-2 text-xl font-semibold text-gray-700">
              Descripción del curso
            </h2>
            <p class="rounded-md bg-purple-50 p-4 text-gray-600">
              {{ course?.description }}
            </p>
          </section>

          <section class="mb-8 flex flex-col space-y-4">
            <h2 class="mb-2 text-xl font-semibold text-gray-700">
              Subir nuevo archivo
            </h2>
            <div class="rounded-md border-2 border-dashed border-gray-300 p-6">
              <input
                id="file"
                ref="fileInput"
                type="file"
                name="file"
                multiple
                class="hidden"
                @change="uploadFile($event)"
              >
              <label
                for="file"
                class="flex cursor-pointer flex-col items-center justify-center"
              >
                <Icon
                  icon="mdi:cloud-upload"
                  class="mb-2 size-12 text-gray-400"
                />
                <span class="text-sm font-medium text-gray-600">Haga clic para subir o arrastre y suelte</span>
              </label>
            </div>

            <!-- Tópicos de analisis realizado -->
            <div
              v-if="fileUploaded"
              class="mt-6"
            >
              <div
                v-if="loadingFileTopics"
                class="flex items-center justify-center"
              >
                <Icon
                  icon="mdi:loading"
                  class="mr-2 size-5 animate-spin text-cyan-500"
                />
                <span class="text-gray-600">Cargando tópicos...</span>
              </div>
              <div v-else>
                <h3 class="mb-4 text-lg font-medium text-gray-700">
                  Tópicos encontrados:
                </h3>
                <div
                  v-for="(file, index) in resultTopics.files"
                  :key="index"
                  class="mb-6 rounded-md bg-gray-50 p-4"
                >
                  <h4 class="mb-2 font-medium text-gray-700">
                    Archivo {{ index + 1 }}
                  </h4>
                  <div
                    v-for="(topic, topicIndex) in file.topics"
                    :key="topicIndex"
                    class="mb-2 flex items-center space-x-2"
                  >
                    <input
                      v-model="file.topics[topicIndex]"
                      type="text"
                      class="grow rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-700 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500"
                    >
                    <button
                      class="rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-600"
                      @click="removeTopic(topicIndex)"
                    >
                      <Icon
                        icon="mdi:close"
                        class="size-5"
                      />
                    </button>
                  </div>
                </div>
                <Button

                  class="mt-2 w-full"
                  @click="acceptFiles"
                >
                  Guardar archivo
                </Button>
              </div>
            </div>

            <!-- Jerarquia -->
            <div v-if="hierarchyRequested">
              <div
                v-if="hierarchyLoading"
                class="flex items-center justify-center"
              >
                <Icon
                  icon="mdi:loading"
                  class="mr-2 size-5 animate-spin text-cyan-500"
                />
                <span class="text-gray-600">Generando jerarquía...</span>
              </div>
              <div
                v-else-if="hierarchy"
                class="flex flex-col space-y-2"
              >
                <div>
                  <TitleComponent
                    text="Jerarquía de tópicos"
                    class="mb-4 text-xl"
                  />
                </div>
                <div class="flex justify-between space-x-4">
                  <div class="flex w-full flex-col gap-3">
                    <div>
                      Jerarquía actual
                    </div>
                    <div class="p-2">
                      <draggable
                        :list="formatCurrentHierarchy"
                        item-key="id"
                        group="topics"
                        :disabled="true"
                        class="flex flex-col gap-4"
                        @start="drag=true"
                        @end="drag=false"
                      >
                        <template #item="{element, index}">
                          <div class="flex flex-col rounded-md bg-gray-300 p-6">
                            <div class="flex space-x-2 py-1">
                              <div>
                                {{ index+1 }}
                              </div>
                              <div>
                                {{ element.name }}
                              </div>
                            </div>
                            <div>
                              <draggable
                                :list="element.children"
                                item-key="id"
                                :disabled="true"
                                group="topics"
                                class="flex flex-col gap-2"
                                @start="drag=true"
                                @end="drag=false"
                              >
                                <template #item="{element: topic, index: topicIndex}">
                                  <div class="space-2 flex rounded-md bg-gray-400 px-4">
                                    <div class="flex space-x-2">
                                      <div>
                                        {{ index+1 }}.{{ topicIndex+1 }}
                                      </div>
                                      <div>
                                        {{ topic.name }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </template>
                      </draggable>
                    </div>
                  </div>
                  <div class="flex w-full flex-col gap-3">
                    <div>
                      Jerarquía Sugerida
                    </div>
                    <div class="p-2">
                      <draggable
                        :list="hierarchyToSubmit"
                        item-key="id"
                        group="topics"
                        class="flex flex-col gap-4"
                        @start="drag=true"
                        @end="drag=false"
                      >
                        <template #item="{element, index}">
                          <div class="flex flex-col rounded-md bg-gray-300 p-6">
                            <div class="flex flex-col gap-2 py-2">
                              <div class="flex gap-2">
                                <div>Unidad</div>
                                <div>
                                  {{ index+1 }}
                                </div>
                              </div>
                              <div class="flex items-center gap-2">
                                <div>
                                  Nombre:
                                </div>
                                <input
                                  v-model="element.name"
                                  class="rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-700 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500"
                                >
                              </div>
                            </div>
                            <div>
                              Temas
                            </div>
                            <draggable
                              :list="element.children"
                              item-key="id"
                              group="topics"
                              class="flex flex-col gap-2"
                              @start="drag=true"
                              @end="drag=false"
                            >
                              <template #item="{element: topic, index: topicIndex}">
                                <div class="space-2 flex items-center justify-between rounded-md bg-gray-400 px-4 shadow-xl">
                                  <div class="flex items-center justify-start gap-2 align-middle">
                                    <div class="cursor-pointer text-gray-900">
                                      <Icon
                                        icon="material-symbols:drag-handle"
                                        class="size-5"
                                      />
                                    </div>
                                    <div>
                                      {{ index+1 }}.{{ topicIndex+1 }}
                                    </div>
                                    <input
                                      v-model="topic.name"
                                      class="rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-700 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500"
                                    >
                                  </div>
                                  <div
                                    class="cursor-pointer rounded-md"
                                    @click="element.children.splice(element.children.indexOf(topic), 1)"
                                  >
                                    Eliminar tema
                                  </div>
                                </div>
                              </template>
                            </draggable>
                          </div>
                        </template>
                      </draggable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>
                    <Button

                      @click="cancelHierarchy"
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div>
                    <Button

                      @click="acceptFile(resultTopics.files[0])"
                    >
                      Regenerar jerarquía
                    </Button>
                  </div>
                  <div>
                    <Button

                      @click="sendHierarchy"
                    >
                      Aceptar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </teacher-layout>
  <Toast
    ref="toast"
  />
</template>

<script>
import coursesApi from '@/api/courses';
import topicsApi from '@/api/topics';
import Button from '@/components/button.vue';
import filesApi from '@/api/files';
import { Icon } from '@iconify/vue';
import TitleComponent from '@/components/title.vue';
import Title from '@/components/title.vue';
import draggable from 'vuedraggable';
import Toast from '@/components/toast.vue';

export default {
  components: {
    Button,
    Icon,
    TitleComponent,
    Title,
    draggable,
    Toast,
  },
  data() {
    return {
      course: {},
      topics: [],
      resultTopics: [],
      loadingFileTopics: false,
      fileUploaded: false,
      courseId: this.$route.params.courseId,
      // hierarchy: { "new": { "holaMundo": [ "Tutorial", "Hola Mundo", "Comentarios", "Primera Suma", "Variables", "Punto y coma", "Modificando Variables", "Variable vs Texto", "Variables vs Texto", "Reglas de identificadores", "Introduccion a tipos de datos" ], "comparacionesYFlujo": [ "Comparaciones de igualdad", "Comparaciones de mayor y menor", "Otros tipos de comparadores", "Introduccion al flujo", "Introduccion a diagramas de flujo", "Saludando a Alex", "Bloques de codigo", "Bloques" ], "funciones": [ "Introduccion a funciones", "Creando una funcion", "Parametros", "Multiples parametros", "Parametros y Argumentos", "Retorno", "Sin retorno", "Retorno antes del final", "Marcopolo" ], "rotaciónYMovimientoDeLaTierra": [ "Rotación de la Tierra", "Eje de Rotación", "Inclinación del Eje", "Conservación del Momento Angular", "Día Solar", "Día Sideral", "Alternancia del Día y la Noche", "Movimiento Aparente del Cielo", "Fuerza de Coriolis", "Vientos y Corrientes Oceánicas", "Geodesia", "Forma de la Tierra", "Desaceleración de la Rotación", "Frenado de Marea", "Importancia para la Vida y la Ciencia." ] }, "current": { "holaMundo": [ "Tutorial", "Hola Mundo", "Comentarios", "Primera Suma", "Variables", "Punto y coma", "Modificando Variables", "Variable vs Texto", "Variables vs Texto", "Reglas de identificadores", "Introduccion a tipos de datos" ], "comparacionesYFlujo": [ "Comparaciones de igualdad", "Comparaciones de mayor y menor", "Otros tipos de comparadores", "Introduccion al flujo", "Introduccion a diagramas de flujo", "Saludando a Alex", "Bloques de codigo", "Bloques" ], "funciones": [ "Introduccion a funciones", "Creando una funcion", "Parametros", "Multiples parametros", "Parametros y Argumentos", "Retorno", "Sin retorno", "Retorno antes del final", "Marcopolo" ] } },
      hierarchy:  {},
      hierarchyRequested: false,
      hierarchyLoading: false,
      hierarchyToSubmit: [],
      drag: false,
    };
  },
  computed: {
    formatCurrentHierarchy() {
      return this.formatHierarchy(this.hierarchy.current);
    },
  },
  watch: {
    $route() {
      this.courseId = this.$route.params.courseId;
      this.getCourse();
    },
  },
  async mounted() {
    this.getCourse();
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Esto navega a la página anterior
    },
    formatHierarchy(hierarchy){
      return Object.keys(hierarchy).map((key) => {
        return {
          name: key,
          originalName: key,
          id: key,
          children: hierarchy[key].map((child) => {
            return {
              name: child,
              originalName: child,
              id: child,
            };
          }),
        };
      });
    },
    cancelHierarchy() {
      this.hierarchy = {};
      this.hierarchyRequested= false;
      this.hierarchyLoading= false;
    },
    async getCourse() {
      this.course = (await coursesApi.getById(this.courseId, {
        withUnits: true,
        withUnitsTopics: true,
      })).data.data;

      this.topics = this.course.units.reduce((acc, unit) => {
        acc.push(...unit.topics);
        return acc;
      }, []);
    },
    async handleFileUpload(event, topicId) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      await topicsApi.awsfiles.create(topicId, formData);

      this.getFilesForTopic(topicId);
    },
    async getFilesForTopic(topicId) {
      const files = (await topicsApi.awsfiles.getAll(topicId)).data.data;

      this.topics = this.topics.map((topic) => {
        if (topic.id === topicId) {
          topic.files = files;
        }
        return topic;
      });
    },
    async uploadFile(event) {
      this.loadingFileTopics = true;
      this.fileUploaded = true;
      try {
        const formData = new FormData();

        const files = event.target.files;

        for (var x = 0; x < files.length; x++) {
          formData.append("files[]", files[x]);
        }

        this.resultTopics = (await coursesApi.files.analyzeTopics(this.courseId, formData)).data.data;

        // await this.getFilesForTopic(this.selectedTopic?.id);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        this.loadingFileTopics = false;
      }
    },
    async removeTopic(topicIndex) {
      this.resultTopics.files = this.resultTopics.files.map((file) => {
        file.topics = file.topics.filter((_, index) => index !== topicIndex);
        return file;
      });
    },
    async acceptFiles() {
      this.fileUploaded = false;
      this.hierarchyRequested = true;
      this.hierarchyLoading = true;

      const awsfiles = this.resultTopics.files.map((file) => {
        return {
          awsfileId: file.awsfileId,
          topics: file.topics,
        };
      });

      this.hierarchy = (await coursesApi.files.generateHierarchy(this.courseId, {
        awsfiles: awsfiles,
      })).data;
      this.hierarchyToSubmit = this.formatHierarchy(this.hierarchy.new);
      this.hierarchyLoading = false;
    },
    async sendHierarchy() {
      const awsfiles = this.resultTopics.files.map((file) => {
        return {
          awsfileId: file.awsfileId,
          topics: file.topics,
        };
      });

      try{
        await coursesApi.files.sendHierarchy(
          this.courseId,
          {awsfiles: awsfiles,
           hierarchy: this.hierarchyToSubmit,
          },
        );
        this.$refs.toast.showMessage('Jerarquía aceptada', 'success');
      }
      catch(err){
        console.log(err);
        this.$refs.toast.showMessage('Ocurrió un error', 'error');
      }
    },
    async downloadFile(fileId) {
      filesApi.download(fileId);
    },
  },
};
</script>
