<template>
  <Radar
    id="radarchart"
    :options="chartOptions"
    :data="chartData"
    ref="chartRef"
  />
</template>

<script>
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Radar } from 'vue-chartjs';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default {
  name: 'RadarChart',
  components: { Radar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    chartRef: {
      default: 'radarChart',
    }
  }
}
</script>

<!--
IMPORTANTE

En caso de querer hacer referencia al grafico para poder editar:

Siendo una variable con ref:
  let radar_chart = ref(null);

y creando el componente con esa ref:
  <RadarChart
    id="Radar_Chart"
    :chart-data="radarChartData"
    :chart-options="radarChartOptions"
    class=""
    ref="radar_chart"
  />

La forma de acceder al grafico y sus variables (referenciando esto https://vue-chartjs.org/guide/#access-to-chart-instance), es:

radar_chart.value.$refs.chartRef.chart

-->
